export async function compressImage(
  file: File,
  maxWidth: number,
  maxHeight: number,
  quality: number,
): Promise<Blob> {
  return new Promise<Blob>((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      const image = new Image();

      image.onload = () => {
        const canvas = document.createElement('canvas');
        let width = image.width;
        let height = image.height;

        const aspectRatio = width / height;

        if (width > maxWidth || height > maxHeight) {
          if (aspectRatio > 1) {
            width = maxWidth;
            height = maxWidth / aspectRatio;
          } else {
            height = maxHeight;
            width = maxHeight * aspectRatio;
          }
        }

        canvas.width = width;
        canvas.height = height;

        const ctx = canvas.getContext('2d');

        if (ctx) {
          // Set the canvas background to transparent
          ctx.clearRect(0, 0, width, height);

          // Draw the image on the canvas
          ctx.drawImage(image, 0, 0, width, height);

          canvas.toBlob(blob => {
            if (blob) {
              resolve(blob);
            } else {
              reject(new Error('Failed to compress image'));
            }
          }, 'image/png'); // Use 'image/png' to store as PNG
        } else {
          reject(new Error('Failed to compress image'));
        }
      };

      image.src = reader.result as string;
    };

    reader.onerror = () => {
      reject(new Error('Failed to read the image file'));
    };

    reader.readAsDataURL(file);
  });
}

export async function blobToFile(theBlob: Blob, fileName: string): Promise<File> {
  return new File([theBlob], fileName, { lastModified: Date.now(), type: theBlob.type });
}

export async function urlToBlob(url: string): Promise<Blob> {
  return fetch(url).then(r => r.blob());
}
export function uint8ArrayToBlob(data: Uint8Array, type: string): Blob {
  return new Blob([data], { type: type });
}
export function convertPNGTOJPG(pngDataUrl: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const img = new Image();

    // Load the PNG image
    img.onload = function () {
      // Create a canvas element
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      // Set the canvas dimensions to match the image
      canvas.width = img.width;
      canvas.height = img.height;
      if (ctx === null) {
        reject(new Error('Failed to convert PNG to JPG'));
        return;
      }
      // Draw the PNG image onto the canvas
      ctx.drawImage(img, 0, 0);

      // Convert the canvas to a JPG image data URL
      const jpgDataUrl = canvas.toDataURL('image/jpeg');

      resolve(jpgDataUrl);
    };

    img.onerror = function (error) {
      reject(error);
    };

    // Set the source of the image to the PNG data URL
    img.src = pngDataUrl as any;
  });
}

export function blobToArrayBuffer(blob: Blob): Promise<ArrayBuffer | null | string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      // The result is an ArrayBuffer
      resolve(reader.result);
    };

    reader.onerror = error => {
      reject(error);
    };

    // Read the Blob as ArrayBuffer
    reader.readAsArrayBuffer(blob);
  });
}
export function isVideo(filename: string) {
  if (filename.includes('video/')) {
    return true;
  }
  return false;
}
export function isAudio(filename: string) {
  if (filename.includes('audio/')) {
    return true;
  }
  return false;
}
export function isImage(filename: string) {
  if (filename.includes('image/')) {
    return true;
  }
  return false;
}
export enum fileTypes {
  unknown = 'unknown',
  PNG = 'image/png',
  GIF = 'image/gif',
  JPEG = 'image/jpeg',
  PDF = 'application/pdf',
  mp3 = 'audio/mpeg',
  mp4 = 'video/mp4',
  zip = 'application/zip',
  doc = 'application/msword',
  docx = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  xls = 'application/vnd.ms-excel',
  xlsx = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  ppt = 'application/vnd.ms-powerpoint',
  pptx = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  txt = 'text/plain',
  csv = 'text/csv',
  json = 'application/json',
  html = 'text/html',
  xml = 'application/xml',
  exe = 'application/x-msdownload',
  svg = 'image/svg+xml',
  tiff = 'image/tiff',
  webp = 'image/webp',
  webm = 'video/webm',
  wmv = 'video/x-ms-wmv',
  avi = 'video/x-msvideo',
  ogg = 'audio/ogg',
  wav = 'audio/wav',
  aac = 'audio/aac',
  wma = 'audio/x-ms-wma',
  mov = 'video/quicktime',
  flv = 'video/x-flv',
  mpeg = 'video/mpeg',
  mpeg4 = 'video/mp4',
  rtf = 'application/rtf',
}
export function getFileTypeFromBlob(blob: Blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onloadend = function () {
      const uint = new Uint8Array(reader?.result?.slice(0, 4) as ArrayBuffer);

      let bytes: string[] = [];
      uint.forEach(byte => {
        bytes.push(byte.toString(16));
      });

      const hex = bytes.join('').toUpperCase();

      // Check the file signature to determine the file type
      let fileType = '';
      if (hex.startsWith('89504E47')) {
        fileType = fileTypes.PNG;
      } else if (hex.startsWith('47494638')) {
        fileType = fileTypes.GIF;
      } else if (hex.startsWith('FFD8FF')) {
        fileType = fileTypes.JPEG;
      } else if (hex.startsWith('25504446')) {
        fileType = fileTypes.PDF;
      } else {
        fileType = fileTypes.unknown;
      }

      resolve(fileType);
    };

    reader.onerror = function (error) {
      reject(error);
    };

    reader.readAsArrayBuffer(blob);
  });
}
export function getFileTypeFromObjectURL(objectURL: string): fileTypes {
  // Extract the base64 data from the data URL
  const base64Data = objectURL.split(',')[1];

  // Decode the base64 data to obtain binary data
  const binaryData = atob(base64Data);

  // Take the first few bytes to determine the file signature
  const signature = binaryData.slice(0, 4);

  // Check the file signature to determine the file type
  let fileType = fileTypes.unknown;

  if (signature === '\x89PNG') {
    fileType = fileTypes.PDF;
  } else if (signature === 'GIF8') {
    fileType = fileTypes.GIF;
  } else if (signature === '\xFF\xD8\xFF') {
    fileType = fileTypes.JPEG;
  } else if (signature === '%PDF') {
    fileType = fileTypes.PDF;
  }

  return fileType;
}
export function gigabytesToBytes(gigabytes: number) {
  const gigabyteConversionFactor = Math.pow(1024, 3);
  const bytes = gigabytes * gigabyteConversionFactor;
  return bytes;
}
