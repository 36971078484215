import React, { ReactElement, createContext, useEffect, useState } from 'react';
import { getCompanys } from '../auth/apiconnect';

export type Company = {
  id?: number;
  name: string;
  logoUrl?: string;
  InvoiceAddress?: {
    companyName?: string;
    street?: string;
    houseNumber?: string;
    postcode?: string;
    city?: string;
    country?: string;
    email?: string;
    taxNumber?: string;
  };
  Employees?: Employees[];
};
export type Employees = {
  id: number;
  User: {
    id: number;
    email?: string;
    currentUser: boolean;
  };
};

type CompanyContextType = {
  companys: Company[] | null;
  setCompanys: React.Dispatch<React.SetStateAction<Company[] | null>>;
};

export const initialCompanyData: Company = {
  id: 0,
  name: '',
  logoUrl: '',
};
export const CompanyContext = createContext<CompanyContextType>({
  companys: [initialCompanyData],
  setCompanys: () => {},
});
export function DataProvider({ children = <></> }): ReactElement {
  const [companys, setCompanys] = useState<Company[] | null>(null);
  useEffect(() => {
    // Simulate fetching data
    setTimeout(() => {
      getCompanys().then(_companys => {
        console.log('ASYNC LOADED COMPANYS', _companys);
        setCompanys(_companys);
      });
    }, 0);
  }, []);

  return (
    <CompanyContext.Provider value={{ companys, setCompanys }}>{children}</CompanyContext.Provider>
  );
}
