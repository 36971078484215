import React, { useState, useEffect, useRef, useContext } from 'react';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { MultiSelect } from 'primereact/multiselect';
import { Chips, ChipsChangeEvent } from 'primereact/chips';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import MainComponent from '../../components/page/main-component';
import Spacer from '../../components/generic/spacer';
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';
import { Chip } from 'primereact/chip';
import { Icon } from '@iconify/react';
import { CustomToast, CustomToastRef } from '../../components/generic/toast';
import './project-groups.css';
import DefaultButton from '../../components/button';
import { useParams } from 'react-router-dom';
import {
  addGroup,
  addGroupMember,
  deleteUsers,
  getGroups,
  getProjectRoles,
  getUsers,
} from '../../func/auth/apiconnect';
import { getRoles } from '@testing-library/react';
import ToastContext from '../../func/context/toastContext';
import verifyEmail from '../../components/generic/verifyEmail';
import { Nullable } from 'primereact/ts-helpers';
export interface Group {
  name: string;
  id: number;
}
interface Role {
  name: string;
  id: number;
}
export enum invitationState {
  Created = 0,
  Invited = 1,
  Requested = 2,
  Declined = 3,
  Accepted = 4,
  Left = 5,
}
interface User {
  id: number | null;
  email: string;
  name: string;
  role: Role | null;
  groups?: Group[] | null;
  joined: invitationState;
}

export default function ProjectGroupEditPage() {
  let emptyUser: User = {
    id: null,
    email: '',
    name: '',
    groups: null,
    role: null,
    joined: invitationState.Created,
  };
  const [users, setUsers] = useState<User[]>([]);
  const [roles, setRoles] = useState<Role[]>([]);
  const [groupName, setGroupName] = useState<string>('');
  const [groupDialog, setGroupDialog] = useState<boolean>(false);
  const [newUsers, setNewUser] = useState<{
    userEmails?: string[];
    Role?: Role;
    Groups?: Group[];
  }>();
  const [userDialog, setUserDialog] = useState<boolean>(false);
  const [singleUserDialog, setSingleUserDialog] = useState<boolean>(false);
  const [deleteUserDialog, setDeleteUserDialog] = useState<boolean>(false);
  const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
  const params = useParams();
  const [globalFilter, setGlobalFilter] = useState<string>('');
  const [groups, setGroups] = useState<Group[]>();
  function verifyandAddMail(value: Nullable<string[]>) {
    if (!value) return;
    let newuserparsed: (string | undefined)[] = value
      ?.map(val => {
        if (verifyEmail(val)) {
          return val.toLowerCase();
        }
      })
      .filter(item => item !== undefined);
    newuserparsed = newuserparsed.filter(function (elem, index, self) {
      return index === self.indexOf(elem);
    });

    setNewUser({ ...newUsers, userEmails: newuserparsed as string[] });
  }
  const toastContext = useContext(ToastContext);
  const dt = useRef<DataTable<User[]>>(null);
  const header = (
    <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
      <h4 className="m-0">User Bearbeiten</h4>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          placeholder="Suchen..."
          onInput={e => {
            const target = e.target as HTMLInputElement;
            setGlobalFilter(target.value);
          }}
        />
      </span>
    </div>
  );
  const loadGroups = () => {
    getGroups(parseInt(params.projectId ?? '')).then((groups: Group[]) => {
      setGroups(groups);
    });
  };
  const loadRoles = () => {
    getProjectRoles(parseInt(params.projectId ?? '')).then((roles: Role[]) => {
      setRoles(roles);
    });
  };
  const loadUsers = () => {
    interface loadedUser {
      active: boolean;
      invitationState: number;
      User: {
        email: string;
        id: number;
      };
      Role: Role;
      Groups: Group[];
    }
    getUsers(parseInt(params.projectId ?? '')).then((users: loadedUser[]) => {
      let userasUser: User[] = users.map((user: loadedUser) => {
        return {
          email: user.User.email,
          id: user.User.id,
          joined: user.invitationState,
          name: user.User.email,
          role: user.Role,
          groups: user.Groups,
        };
      });
      setUsers(userasUser);
    });
  };
  useEffect(() => {
    loadGroups();
    loadRoles();
    loadUsers();
  }, []);
  const openNew = () => {
    setUserDialog(true);
  };
  const hideDialog = () => {
    setUserDialog(false);
  };
  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>, name: string) => {
    /* const val = (e.target && e.target.value) || '';
    let _user = { ...user };

    // @ts-ignore
    _user[`${name}`] = val;

    setUser(_user);*/
  };

  const saveUser = async () => {
    if (!newUsers?.userEmails || newUsers?.userEmails.length === 0) {
      toastContext.showToast({
        content: 'Bitte geben Sie eine E-Mail ein',
        sevety: 'error',
      });
      return;
    }
    if (!newUsers?.Role) {
      toastContext.showToast({ content: 'Bitte wählen Sie eine Rolle aus', sevety: 'error' });
      return;
    }
    if (!newUsers?.Groups || newUsers?.Groups.length === 0) {
      toastContext.showToast({ content: 'Bitte wählen Sie eine Gruppe aus', sevety: 'error' });
      return;
    }
    await addGroupMember(
      newUsers?.userEmails ?? [],
      newUsers?.Role?.id ?? 0,
      newUsers?.Groups?.map(val => val.id) ?? [],
      parseInt(params.projectId ?? ''),
    );
    loadUsers();
    toastContext.showToast({
      content: 'Benutzer wurden hinzugefügt',
      sevety: 'success',
    });
    setUserDialog(false);
    setSingleUserDialog(false);
    setNewUser(undefined);
  };

  const exportCSV = () => {
    dt.current?.exportCSV();
  };
  const confirmDeleteSelected = () => {
    setDeleteUserDialog(true);
  };

  const userDialogFooter = (
    <React.Fragment>
      <Button label="Speichern" icon="pi pi-check" onClick={saveUser} />
    </React.Fragment>
  );

  const leftToolbarTemplate = () => {
    let classes =
      'deletedButton ' + (!selectedUsers || !selectedUsers.length ? 'disabledDelete' : '');
    return (
      <div className="groupEditButton">
        <DefaultButton text="Benutzer Hinzufügen" action={() => openNew()}></DefaultButton>{' '}
        <DefaultButton text="Gruppe Hinzufügen" action={() => setGroupDialog(true)}></DefaultButton>
        <DefaultButton
          icon="material-symbols:delete"
          classes={classes}
          disabled={!selectedUsers || !selectedUsers.length}
          text="Löschen"
          action={() => confirmDeleteSelected()}
        ></DefaultButton>
      </div>
    );
  };
  const deleteUser = async () => {
    if (!selectedUsers) return;
    let selectedUsersIds = selectedUsers.map(val => val.id);
    let selectedUsersIdsWithoutNull = [];
    for (let id of selectedUsersIds) {
      if (id !== null && id !== undefined) {
        selectedUsersIdsWithoutNull.push(id);
      }
    }
    // TODO: function is not ready
    await deleteUsers(selectedUsersIdsWithoutNull, parseInt(params.projectId ?? ''));
    loadUsers();
    toastContext.showToast({
      content: 'Benutzer wurden gelöscht',
      sevety: 'success',
    });
    setDeleteUserDialog(false);
    return;
    /* await deleteUsers(selectedUsersIds, parseInt(params.projectId ?? '')
   
  
    let _users = users.filter(val => val.id !== user.id);

    setUsers(_users);
    setDeleteUserDialog(false);
    setUser(emptyUser);

    */
  };
  const hideDeleteUserDialog = () => {
    setDeleteUserDialog(false);
  };
  const confirmDeleteUser = (User: User) => {
    setDeleteUserDialog(true);
  };
  const deleteUserDialogFooter = (
    <React.Fragment>
      <Button label="Nein" icon="pi pi-times" outlined onClick={hideDeleteUserDialog} />
      <Button label="Ja" icon="pi pi-check" severity="danger" onClick={deleteUser} />
    </React.Fragment>
  );
  const deleteSelectedUsers = () => {
    let _users = users.filter(val => !selectedUsers.includes(val));

    setUsers(_users);
    setDeleteUserDialog(false);
    setSelectedUsers([]);
    toastContext.showToast({
      sevety: 'success',
      content: 'Benutzer wurden gelöscht',
    });
  };

  const rightToolbarTemplate = () => {
    return (
      <Button
        label="Exportieren"
        icon={<Icon style={{ marginRight: '5px' }} icon={'bi:save'}></Icon>}
        className="p-button-help exportButton"
        onClick={exportCSV}
      />
    );
  };
  const renderGroupChipSelect = (Users: User) => {
    if (Users.groups === null) {
      return;
    }
    var returnVal: React.ReactElement[] = [];
    for (let groups of Users.groups ?? []) {
      returnVal.push(<Chip style={{ marginRight: '5px' }} label={groups.name}></Chip>);
    }
    return returnVal;
  };
  const renderGroupMultiSelect = () => {
    return (
      <MultiSelect
        value={newUsers?.Groups ?? []}
        onChange={e => setNewUser({ ...newUsers, Groups: e.value })}
        options={groups ?? []}
        optionLabel="name"
        filter
        placeholder="Gruppen Auswählen"
        maxSelectedLabels={3}
        className="w-full md:w-20rem"
      />
    );
  };
  const editUser = (User: User) => {
    setUserDialog(true);
  };
  const setSingleUserDialogOpen = (User: User) => {
    setNewUser({
      userEmails: [User.email],
      Role: User.role ?? undefined,
      Groups: User.groups ?? undefined,
    });
    setSingleUserDialog(true);
  };
  const renderEdit = (rowData: User) => {
    return (
      <React.Fragment>
        <Button
          icon={<Icon icon={'material-symbols:edit'}></Icon>}
          rounded
          outlined
          className="mr-2"
          style={{ marginRight: '5px' }}
          onClick={() => setSingleUserDialogOpen(rowData)}
        />
      </React.Fragment>
    );
  };
  const renderRole = (User: User) => {
    return <Chip label={User.role?.name}></Chip>;
  };
  const covertJoinedStatus = (User: User) => {
    return User.joined === invitationState.Accepted ? (
      <Icon icon={'material-symbols:check'} style={{ color: 'green' }}></Icon>
    ) : User.joined === invitationState.Left ? (
      <Icon icon={'pepicons-pop:leave'} style={{ color: 'gray' }}></Icon>
    ) : User.joined === invitationState.Declined ? (
      <Icon icon={'maki:cross'} color="red"></Icon>
    ) : User.joined === invitationState.Created ? (
      <Icon icon={'mdi:clock'} color="gray"></Icon>
    ) : User.joined === invitationState.Invited ? (
      <Icon icon={'material-symbols:mail'} color="gray"></Icon>
    ) : (
      'R'
    );
  };
  return (
    <MainComponent
      pageContent={
        <>
          <span style={{ fontSize: '24px', fontFamily: 'PoppinsSemiBold' }}>
            Gruppen Einstellungen
          </span>
          <Spacer space={50}></Spacer>
          <Toolbar
            className="mb-4"
            left={leftToolbarTemplate}
            right={rightToolbarTemplate}
          ></Toolbar>
          <div style={{ padding: 10 }}>
            {groups?.map((group: Group) => (
              <Chip label={group.name}></Chip>
            ))}
          </div>
          <DataTable
            emptyMessage="Keine Benutzer gefunden"
            ref={dt}
            value={users}
            selection={selectedUsers}
            onSelectionChange={e => {
              if (Array.isArray(e.value)) {
                setSelectedUsers(e.value);
              }
            }}
            dataKey="id"
            paginator
            rows={10}
            rowsPerPageOptions={[5, 10, 25]}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Zeige {first} bis {last} von {totalRecords} Benutzern"
            globalFilter={globalFilter}
            header={header}
            selectionMode={'multiple'}
          >
            <Column selectionMode="multiple" exportable={false}></Column>
            <Column field="email" header="E-Mail" sortable style={{ minWidth: '12rem' }}></Column>
            <Column
              field="joined"
              header="Beigetreten"
              sortable
              body={covertJoinedStatus}
              style={{ width: '1rem' }}
            ></Column>
            <Column
              field="groups"
              header="Gruppen"
              sortable
              body={renderGroupChipSelect}
              style={{ minWidth: '16rem' }}
            ></Column>
            <Column
              field="role"
              header="Rolle"
              sortable
              body={renderRole}
              style={{ minWidth: '16rem' }}
            ></Column>
            <Column field="" body={renderEdit} style={{}}></Column>
          </DataTable>
          <Dialog
            draggable={false}
            visible={userDialog}
            style={{ width: '32rem' }}
            breakpoints={{ '960px': '75vw', '641px': '90vw' }}
            header="Benutzer"
            modal
            className="p-fluid"
            footer={userDialogFooter}
            onHide={hideDialog}
          >
            <div className="field">
              <label htmlFor="email" className="font-bold">
                E-Mail (Liste)
              </label>
              <Chips
                value={newUsers ? newUsers?.userEmails : []}
                addOnBlur
                onChange={e => {
                  verifyandAddMail(e.value);
                }}
                separator=","
              />

              {!newUsers && <small className="p-error">E-Mail ist ein Pflichtfeld.</small>}
            </div>
            <Spacer></Spacer>
            {renderGroupMultiSelect()}
            <Spacer></Spacer>
            <Dropdown
              value={newUsers?.Role}
              onChange={e => setNewUser({ ...newUsers, Role: e.value })}
              options={roles}
              optionLabel="name"
              placeholder="Select Role"
              filter
              className="w-full md:w-14rem"
            />
          </Dialog>
          <Dialog
            draggable={false}
            visible={singleUserDialog}
            style={{ width: '32rem' }}
            breakpoints={{ '960px': '75vw', '641px': '90vw' }}
            header="Benutzer"
            modal
            className="p-fluid"
            footer={userDialogFooter}
            onHide={() => {
              setSingleUserDialog(false);
              setNewUser(undefined);
            }}
          >
            <div className="field">
              <label htmlFor="email" className="font-bold">
                E-Mail
              </label>
              <InputText
                disabled={true}
                onChange={e => {
                  setNewUser({ ...newUsers, userEmails: [e.target.value] });
                }}
                value={newUsers?.userEmails ? newUsers.userEmails[0] : ''}
              />

              {!newUsers && <small className="p-error">E-Mail ist ein Pflichtfeld.</small>}
            </div>
            <Spacer></Spacer>
            {renderGroupMultiSelect()}
            <Spacer></Spacer>
            <Dropdown
              value={newUsers?.Role}
              onChange={e => setNewUser({ ...newUsers, Role: e.value })}
              options={roles}
              optionLabel="name"
              placeholder="Select Role"
              filter
              className="w-full md:w-14rem"
            />
          </Dialog>
          <Dialog
            draggable={false}
            visible={groupDialog}
            style={{ width: '32rem' }}
            breakpoints={{ '960px': '75vw', '641px': '90vw' }}
            header="Gruppe Hinzufügen"
            modal
            footer={<></>}
            onHide={() => setGroupDialog(false)}
          >
            <>
              <InputText
                style={{ width: '100%', marginBottom: 10 }}
                type="text"
                placeholder="Gruppenname"
                value={groupName}
                onChange={event => {
                  setGroupName(event.target.value);
                }}
              ></InputText>
              <DefaultButton
                action={() => {
                  addGroup(groupName, parseInt(params.projectId ?? '')).then(() => {
                    toastContext.showToast({ content: 'Gruppe erstellt', sevety: 'success' });
                    setGroupDialog(false);
                    loadGroups();
                  });
                }}
                text="Speichern"
              ></DefaultButton>
            </>
          </Dialog>
          <Dialog
            draggable={false}
            visible={deleteUserDialog}
            style={{ width: '32rem' }}
            breakpoints={{ '960px': '75vw', '641px': '90vw' }}
            header="Bestätigen"
            modal
            footer={deleteUserDialogFooter}
            onHide={hideDeleteUserDialog}
          >
            <div className="confirmation-content">
              <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
              {users && <span>Bist du dir sicher, dass du die Benutzer entfernen möchtest?</span>}
            </div>
          </Dialog>
        </>
      }
    ></MainComponent>
  );
}
