import { InputText } from 'primereact/inputtext';
import DefaultButton from '../button';
import MenuBell from '../../menu/menuBell';
import MenuItem from '../../menu/menuitem';
import { IfIsPage, PAGES } from '../../enum/pages';
import UserSettings from '../settings/user';
import { useContext, useEffect, useRef } from 'react';
import ToastContext from '../../func/context/toastContext';
import { Toast } from 'primereact/toast';
import { CustomToast, CustomToastRef } from '../generic/toast';

export default function MainComponent({ pageContent = <></> }) {
  const toastContext = useContext(ToastContext);
  toastContext.toastRef = useRef<CustomToastRef>(null);
  return (
    <div className="Container">
      <div className="menubar">
        <div className="logoContainer">
          <img src="/logo.svg"></img>
        </div>
        <div className="searchBar">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              value={''}
              onChange={e => () => {}}
              placeholder="Suche nach Unternehmen, Nutzern oder Projekten"
            />
          </span>
        </div>
        <div className="leftMenu">
          <div style={{ paddingLeft: '10px' }}></div>
          <MenuBell />
          <UserSettings />
        </div>
      </div>
      <div className="BottomContainer">
        <div className="sideBar">
          <div className="sidebarElement">
            <MenuItem
              icon="ant-design:team-outlined"
              hightlighted={IfIsPage(PAGES.DASHBOARD)}
              link="/"
              text="Dashboard"
            ></MenuItem>
            <MenuItem
              icon="akar-icons:briefcase"
              hightlighted={IfIsPage(PAGES.COMPANYS)}
              text="Produktionsfirma"
              link="/companys"
            ></MenuItem>
            <MenuItem
              icon="charm:clipboard-tick"
              hightlighted={IfIsPage(PAGES.PROJECTS)}
              link="/projects"
              text="Projekte"
            ></MenuItem>
            {/* <MenuItem
              icon="fluent:shifts-activity-24-filled"
              hightlighted={false}
              text="Activities"
            ></MenuItem> */}
          </div>
        </div>
        <div className="innerDiv" style={{ color: 'black'}}>
          {pageContent}
        </div>
      </div>
      <CustomToast ref={toastContext.toastRef} />
    </div>
  );
}
