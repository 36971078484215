import { useEffect, useState } from 'react';
import CompanyExpand from '../../components/company/company-expand';
import MainComponent from '../../components/page/main-component';
import { Payment, PaymentState } from '../../func/context/projectContext';
import { downloadInvoice, getActivations } from '../../func/auth/apiconnect';
import { useParams } from 'react-router-dom';
import { Card } from 'primereact/card';
import { Tag } from 'primereact/tag';
import { getFormattedDate } from '../../components/generic/date';
import DefaultButton from '../../components/button';
import './company-details.css';
import { ScrollPanel } from 'primereact/scrollpanel';

export default function CompanyDetailsPage() {
  const [activations, setActivations] = useState<Payment[]>([]);
  const params = useParams();
  const handleDownload = async (id: number) => {
    try {
      let downloadBlob = await downloadInvoice(id);

      const url = URL.createObjectURL(downloadBlob);

      const link = document.createElement('a');
      link.href = url;
      link.download = 'Invoice.pdf';

      // Trigger a click event on the link to start the download
      link.click();

      // Clean up by revoking the object URL
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };
  useEffect(() => {
    getActivations(parseInt(params.companyId ?? '0')).then((activations: Payment[]) =>
      setActivations([...activations.sort((b, a) => a.id - b.id)]),
    );
  }, []);
  return (
    <MainComponent
      pageContent={
        <>
          <CompanyExpand></CompanyExpand>
          <Card style={{ marginTop: '20px' }}>
            <h3>Aktivierungen</h3>
            <ScrollPanel style={{ width: '100%', height: '150px' }}>
              {activations.map(activation => {
                return (
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div
                      style={{
                        paddingTop: '10px',
                        paddingBottom: '10px',
                        display: 'flex',
                        flexDirection: 'row',
                      }}
                    >
                      <Tag
                        severity={
                          activation.state === PaymentState.successful
                            ? 'success'
                            : activation.state === PaymentState.failed
                            ? 'danger'
                            : activation.state === PaymentState.awaitingPayment
                            ? 'info'
                            : activation.state === PaymentState.cancelled
                            ? 'warning'
                            : 'danger'
                        }
                        value={
                          activation.state === PaymentState.successful
                            ? 'Erfolgreich'
                            : activation.state === PaymentState.failed
                            ? 'Fehlgeschlagen'
                            : activation.state === PaymentState.awaitingPayment
                            ? 'Warte auf Zahlung'
                            : activation.state === PaymentState.cancelled
                            ? 'Abgebrochen'
                            : activation.state === PaymentState.created
                            ? 'Erstellt'
                            : 'Fehler'
                        }
                      ></Tag>
                      <b>
                        Projekt: {activation.Project?.name} vom {getFormattedDate(activation.from)}{' '}
                        bis zum {getFormattedDate(activation.to)}
                      </b>
                      {activation.state === PaymentState.successful && (
                        <DefaultButton
                        icon='bx:bxs-download'
                          action={() => {
                            handleDownload(activation.id);
                          }}
                          classes="invoicedownload"
                          text="Rechnung"
                        ></DefaultButton>
                      )}
                    </div>
                  </div>
                );
              })}
            </ScrollPanel>
          </Card>
        </>
      }
    ></MainComponent>
  );
}
