import DefaultButton from '../../components/button';

interface DownloadButtonProps {
  blob: Blob | undefined;
  fileName: string;
  fileType: string;
}
export const DownloadButton: React.FC<DownloadButtonProps> = ({ blob, fileName, fileType }) => {
  const downloadFile = () => {
    const _blob = new Blob([blob!], { type: fileType });
    const url = URL.createObjectURL(_blob!);

    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;

    // Trigger a click event on the link to start the download
    link.click();

    // Clean up by revoking the object URL
    URL.revokeObjectURL(url);
  };
  if (!blob) return null;
  return (
    <>
      <DefaultButton maxWidth='300px' icon='material-symbols:download' text={'Download ' + fileName} action={downloadFile}></DefaultButton>
    </>
  );
};

export default DownloadButton;
