import { MultiSelect, MultiSelectChangeEvent } from 'primereact/multiselect';
import { useEffect, useState } from 'react';
import { Group } from '../../pages/project/project-groups';
import { getGroups } from '../../func/auth/apiconnect';
import { useParams } from 'react-router-dom';
import { group } from 'console';
interface GroupSelectionInterface {
  onSelect: (groupIds: number[]) => void;
  defaultSelectedGroups?: Group[];
}
export default function GroupSelection(groupParams: GroupSelectionInterface) {
  const [groups, setGroups] = useState<Group[]>([]);
  const [selectedGroups, setSelectedGroups] = useState<Group[]>([]);
  const [defaultSelectedGroups, setDefaultSelectedGroups] = useState<Group[]>([]);
  const params = useParams();
  useEffect(() => {
    if (!groupParams.defaultSelectedGroups) {
      setSelectedGroups(groups);
    } else {
      setSelectedGroups(
        defaultSelectedGroups.map(
          (group: Group) => groups.find((g: Group) => g.id === group.id) as Group,
        ),
      );
    }
    groupParams.onSelect(groups.map((group: Group) => group.id));
  }, [groups]);
  useEffect(() => {
    if (groupParams.defaultSelectedGroups !== undefined) {
      setDefaultSelectedGroups(groupParams.defaultSelectedGroups);
    }
  }, [groupParams.defaultSelectedGroups]);
  useEffect(() => {
    getGroups(parseInt(params.projectId ?? '0')).then(groups => {
      setGroups(groups);
    });
  }, []);
  return (
    <>
      <MultiSelect
        selectedItemsLabel="Alle"
        value={selectedGroups}
        onChange={(e: MultiSelectChangeEvent) => {
          setSelectedGroups(e.value);
          groupParams.onSelect(e.value.map((group: Group) => group.id));
        }}
        options={groups}
        optionLabel="name"
        placeholder="Auswählen"
        selectAll={true}
        emptyFilterMessage="Keine Gruppen gefunden"
        maxSelectedLabels={3}
        className="w-full md:w-20rem"
      />
    </>
  );
}
