import './upload-file.css';
import MainComponent from '../../components/page/main-component';
import { FileUpload } from 'primereact/fileupload';
import { useContext, useEffect, useRef, useState } from 'react';
import DefaultButton from '../../components/button';
import {
  compressImage,
  getFileTypeFromBlob,
  gigabytesToBytes,
  isImage,
} from '../../components/generic/compress';
import { encryptFileBlob } from '../../components/generic/encrypt';
import { FileUploadTypes, getFileInformation, postFile } from '../../func/auth/apiconnect';
import { useNavigate, useParams } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { FolderSelect } from './folder-select';
import GroupSelection from '../../components/group/group-selection';
import ToastContext from '../../func/context/toastContext';
import { SimpleFile } from '../project/project-details';
import { Group } from '../project/project-groups';
export type customFile = File & { objectURL: string };
export function UploadFile() {
  const [totalSize, setTotalSize] = useState(0);
  const fileUploadRef = useRef<FileUpload>(null);
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [selectedGroups, setSelectedGroups] = useState<number[]>([]);
  const [fileName, setFileName] = useState('');
  const [defaultGroups, setDefaultGroups] = useState<Group[]>([]);
  const navigate = useNavigate();
  const toastContext = useContext(ToastContext);
  const [selectedFolder, setSelectedFolder] = useState<string>();
  const [defaultSelectedFolder, setDefaultSelectedFolder] = useState<string>();
  const [imageBlob, setImageBlob] = useState('');

  useEffect(() => {
    if (params.fileId !== 'NEW') {
      getFileInformation(parseInt(params.fileId ?? '0')).then((file: SimpleFile) => {
        setFileName(file.fileName);
        setDefaultGroups(file?.Groups ?? []);
        setDefaultSelectedFolder(file.parent.toString());
      });
    }
  }, []);
  const customFileUpload = async () => {
    let file = fileUploadRef.current?.getFiles()[0] as customFile;
    setLoading(true);
    if (!file && params.fileId === 'NEW') {
      setLoading(false);
      toastContext.showToast({
        content: 'Keine Datei ausgewählt',
        sevety: 'error',
      });
      return;
    }
    if (!fileName.length) {
      setLoading(false);
      toastContext.showToast({
        content: 'Kein Dateiname',
        sevety: 'error',
      });
      return;
    }
    if (!selectedGroups.length) {
      setLoading(false);
      toastContext.showToast({
        content: 'Keine Gruppe ausgewählt',
        sevety: 'error',
      });
      return;
    }
    let encryptedFile;
    if (file) {
      encryptedFile = await encryptFileBlob(
        isImage(file.type) ? await compressImage(file, 800, 600, 0.8) : file,
        fileName,
      );
    }
    //const fileBlob = new Blob([encryptedFile], { type: file.type });
    //const encryptedFile = await encryptFileBlob(fileBlob, file.name);
    // fileBlob = await fetch(file.objectURL).then((r) => r.blob()); //blob:url
    let response = await postFile({
      projectId: parseInt(params.projectId ?? '0'),
      fileName: fileName,
      groupIds: selectedGroups,
      type: FileUploadTypes.File.toString(),
      parent: selectedFolder ? parseInt(selectedFolder) : 0,
      confirmationRequired: false,
      file: encryptedFile,
      id: parseInt(params.fileId ?? '') ?? undefined,
    });

    try {
      navigate('/view-file/' + response.id);
      toastContext.showToast({
        content: 'Erfolgreich hochgeladen',
        sevety: 'success',
      });
    } catch (error) {}
    setLoading(false);
  };
  async function setFileAsBlob() {
    if (!fileUploadRef.current?.getFiles().length) {
      setTimeout(setFileAsBlob, 500);
      return;
    }
    let file = fileUploadRef.current?.getFiles()[0] as customFile;
    if (imageBlob === file.objectURL) {
      setTimeout(setFileAsBlob, 500);
      return;
    }
    let fileBlob = await compressImage(file, 800, 600, 0.8);
    //setImageBlob(file.objectURL);
    setImageBlob(URL.createObjectURL(fileBlob));

    console.log(await getFileTypeFromBlob(fileBlob));
  }

  return (
    <MainComponent
      pageContent={
        <>
          <h2>Datei Hochladen</h2>
          <FileUpload
            ref={fileUploadRef}
            name="demo[]"
            customUpload={true}
            accept="*"
            maxFileSize={gigabytesToBytes(1)}
            emptyTemplate={<p className="m-0">Datei per Drag & Drop hochladen</p>}
          />
          <h3>Datei Namen eingeben</h3>
          <InputText value={fileName} onChange={(e: any) => setFileName(e.target.value)} />
          <h3>Mit Gruppen teilen</h3>
          <GroupSelection
            defaultSelectedGroups={defaultGroups}
            onSelect={function (groupIds: number[]): void {
              setSelectedGroups(groupIds);
            }}
          ></GroupSelection>
          <h3>Order Auswählen</h3>
          <FolderSelect
            defaultFolderSelect={defaultSelectedFolder}
            onSelect={folderId => {
              setSelectedFolder(folderId);
            }}
          ></FolderSelect>
          <DefaultButton
            maxWidth="200px"
            loading={loading}
            action={() => customFileUpload()}
            text="Speichern"
          ></DefaultButton>
        </>
      }
    ></MainComponent>
  );
}
