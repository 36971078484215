import { ScrollPanel } from 'primereact/scrollpanel';
import React, { useEffect, useRef, useState } from 'react';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url,
).toString();
interface PdfViewerProps {
  src: string;
}

export const PdfViewer: React.FC<PdfViewerProps> = ({ src }) => {
  const [numPages, setNumPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState(1);
  const containerRef = useRef(null);
  const [pageHeight, setPageHeight] = useState(window.innerHeight);

  const [isButtonUnlocked, setIsButtonUnlocked] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  function onDocumentLoadSuccess({ numPages, height }: any): void {
    setNumPages(numPages);
  }
  const handleScroll = () => {
    if (containerRef.current) {
      let container = containerRef.current as any;
      const currentPosition = container.scrollTop;
      setScrollPosition(currentPosition);
      // You can adjust the threshold as needed
      const scrollThreshold = 1000;
      setIsButtonUnlocked(currentPosition > container.scrollHeight - scrollThreshold);
    }
  };
  useEffect(() => {
    const handleResize = () => {
      setPageHeight(window.innerHeight);
    };

    // Attach the resize event listener
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const handlePageChange = (newPage: any) => {
    setCurrentPage(newPage);
  };
  return (
    <>
      <div className="pdfLayerContainer">
        <div
          className=""
          onScroll={handleScroll}
          ref={containerRef}
          style={{ height: pageHeight - 130 + 'px', overflow: 'scroll',marginBottom:'-130px',paddingBottom:'130px' }}
        >
          <Document
            file={src}
            onScroll={onDocumentLoadSuccess}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            {Array.from(new Array(numPages), (el, index) => (
              <Page
                loading=""
                renderAnnotationLayer={false}
                key={`page_${index + 1}`}
                pageNumber={index + 1}
              />
            ))}
          </Document>
        </div>
      </div>
    </>
  );
};

export default PdfViewer;
