import React, { useState, useRef, useEffect, ChangeEvent } from 'react';
import DefaultButton from '../../../components/button';
import './audioPlayer.css';
interface AudioPlayerProps {
  src: string;
  audioFileName?: string;
}

export const AudioPlayer: React.FC<AudioPlayerProps> = ({ src, audioFileName }) => {
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);

  useEffect(() => {
    const audioElement = audioRef.current;

    const updateTime = () => {
      if (audioElement) {
        setCurrentTime(audioElement.currentTime);
      }
    };

    const updateDuration = () => {
      if (audioElement) {
        setDuration(audioElement.duration);
      }
    };

    if (audioElement) {
      audioElement.addEventListener('timeupdate', updateTime);
      audioElement.addEventListener('loadedmetadata', updateDuration);
    }

    return () => {
      if (audioElement) {
        audioElement.removeEventListener('timeupdate', updateTime);
        audioElement.removeEventListener('loadedmetadata', updateDuration);
      }
    };
  }, []);

  const playPauseHandler = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const stopHandler = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
      setIsPlaying(false);
    }
  };

  const onSeek = (event: ChangeEvent<HTMLInputElement>) => {
    const newTime = parseFloat(event.target.value);
    if (audioRef.current) {
      audioRef.current.currentTime = newTime;
      setCurrentTime(newTime);
    }
  };

  const formatTime = (time: number): string => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  return (
    <div className={'audioFilePlayerContainer'}>
      <h3>{audioFileName}</h3>
      <audio ref={audioRef} src={src} />
      <div className={'uploadFileButton'}>
        <DefaultButton
          text={isPlaying ? 'Pause' : 'Play'}
          action={playPauseHandler}
        ></DefaultButton>
        <DefaultButton text="Stop" action={stopHandler}></DefaultButton>
      </div>
      <div>
        <span>Time: {formatTime(currentTime)}</span>
        <span> / </span>
        <span>Duration: {formatTime(duration)}</span>
      </div>
      <input
        style={{ width: '50%' }}
        type="range"
        min="0"
        max={duration || 0}
        step="1"
        value={currentTime}
        onChange={onSeek}
      />
    </div>
  );
};
