import { Icon } from '@iconify/react';
import { Dialog } from 'primereact/dialog';
import { useContext, useEffect, useState } from 'react';
import { getNotifications, getProjects, joinGroup, markAsRead } from '../func/auth/apiconnect';
import { Badge } from 'primereact/badge';
import DefaultButton from '../components/button';
import ToastContext, { toastContext } from '../func/context/toastContext';
import { ProjectContext } from '../func/context/projectContext';
import { useNavigate } from 'react-router-dom';
export interface notificationInterface {
  id: number;
  notificationType: noticeType;
  read: boolean;
  Project: {
    name: string;
    id: number;
    Company: {
      name: string;
      id: number;
    };
  };
}
enum noticeType {
  message = 0,
  invitation = 1,
}
export default function MenuBell() {
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const toastContext = useContext(ToastContext);
  const navigate = useNavigate();
  const { projects, setProjects } = useContext(ProjectContext);
  const [notifications, setNotifications] = useState<notificationInterface[]>([]);
  async function downloadNotifications() {
    let notification = await getNotifications();
    setNotifications([...notification].sort((a, b) => b.id - a.id));
  }
  async function joinProject(projectId: number) {
    setLoading(true);
    let response = await joinGroup(projectId);
    setLoading(false);
    toastContext.showToast({ content: 'Du bist dem Projekt beigetreten', sevety: 'success' });
    getProjects().then(projects => {
      setProjects(projects);
    });
  }
  useEffect(() => {
    setInterval(() => {
      downloadNotifications();
    }, 30000);
    downloadNotifications();
  }, []);
  return (
    <>
      <div
        onClick={() => setVisible(true)}
        style={{ display: 'flex', alignItems: 'center', alignSelf: 'center' }}
      >
        <div className="notificationIcon">
          <Icon style={{ fontSize: '25px' }} icon="akar-icons:bell"></Icon>
          {notifications.filter(notification => notification.read === false).length ? (
            <Badge
              size={'normal'}
              value={notifications.filter(notification => notification.read === false).length}
              severity="danger"
            ></Badge>
          ) : null}
        </div>
      </div>
      <Dialog
        header="Benachrichtigungen"
        visible={visible}
        position="top-right"
        style={{ width: '50vw' }}
        onHide={() => setVisible(false)}
        footer={<></>}
        draggable={false}
        resizable={false}
      >
        {notifications.map(notification => {
          if (notification.notificationType === noticeType.invitation)
            return (
              <div className="notificationWrapper">
                <p>
                  Du wurdest von {notification.Project.Company.name} in das Projekt{' '}
                  {notification.Project.name} eingeladen
                </p>
                {!notification.read ? (
                  <div className={'notificationAction'}>
                    <DefaultButton
                      action={() => {
                        joinProject(notification.Project.id);
                      }}
                      text="Beitreten"
                    ></DefaultButton>
                    <DefaultButton
                      classes="decline"
                      icon="ic:baseline-minus"
                      text="Ablehnen"
                    ></DefaultButton>
                  </div>
                ) : null}
              </div>
            );
          if (notification.notificationType === noticeType.message) {
            return (
              <div className="notificationWrapper">
                <p>
                  Eine neue Nachricht von {notification.Project.Company.name} im Projekt:{' '}
                  {notification.Project.name}
                </p>
                {notification.read===false && (
                  <DefaultButton
                    text="Öffnen"
                    action={() => {
                      markAsRead(notification.id).then(() => {
                        window.location.href =
                          '/project/' + notification.Project.id + '/notification';
                        setVisible(false);
                      });
                    }}
                  ></DefaultButton>
                )}
              </div>
            );
          }
        })}
      </Dialog>
    </>
  );
}
