import { useContext, useEffect, useRef, useState } from 'react';
//import { useRouter } from 'next/router';
import { Menu } from 'primereact/menu';
import { MenuItem } from 'primereact/menuitem';
import { Toast } from 'primereact/toast';
import { Icon } from '@iconify/react';
import { useNavigate, useParams } from 'react-router-dom';
import './project.css';
import { PAGES } from '../../enum/pages';
import { deleteProject, getProjects, leaveProject } from '../../func/auth/apiconnect';
import { Project, ProjectContext, rightKey } from '../../func/context/projectContext';
import { User } from '../profile/profile';
import DefaultButton from '../button';
export default function ProjectSettings({ onEditMode = () => {}, onGroupEdit = () => {} }) {
  const navigate = useNavigate();
  const params = useParams();
  const menuRight = useRef<Menu>(null);
  const { projects, setProjects } = useContext(ProjectContext);
  const [project, setProject] = useState<Project>();
  useEffect(() => {
    setProject(projects?.find(project => project.id === parseInt(params.projectId ?? '0')));
  }, [projects]);
  const toast = useRef<Toast>(null);
  const adminOptions = [
    {
      label: 'Bearbeiten!',
      icon: (
        <>
          <Icon className="primaryFontColor" icon={'material-symbols:edit'}></Icon>
          <span style={{ paddingRight: '5px' }}></span>
        </>
      ),
      command: () => {
        onEditMode();
      },
    },
    {
      label: 'Gruppen',
      icon: (
        <>
          <Icon className="primaryFontColor" icon={'ic:round-groups-2'}></Icon>
          <span style={{ paddingRight: '5px' }}></span>
        </>
      ),
      command: () => {
        onGroupEdit();
      },
    },
    {
      label: 'Teilen',
      icon: (
        <>
          <Icon className="primaryFontColor" icon={'ion:share-outline'}></Icon>
          <span style={{ paddingRight: '5px' }}></span>
        </>
      ),
      command: () => {
        navigate(PAGES.ShareProject.replace(':projectId', params.projectId ?? '0'));
      },
    },
    {
      style: { backgroundColor: '#ff060636', borderRadius: '5px' },
      label: 'Löschen',
      icon: (
        <>
          <Icon className="primaryFontColor" icon={'ic:round-delete'}></Icon>
          <span style={{ paddingRight: '5px' }}></span>
        </>
      ),
      command: () => {
        deleteProject(parseInt(params.projectId ?? '0'));
      },
    },
  ];
  const UserOptions = [
    {
      style: { backgroundColor: '#ff060636', borderRadius: '5px' },
      label: 'Verlassen',
      icon: (
        <>
          <Icon className="primaryFontColor" icon={'ic:round-delete'}></Icon>
          <span style={{ paddingRight: '5px' }}></span>
        </>
      ),
      command: () => {
        leaveProject(parseInt(params.projectId ?? '0')).then(() => {
          navigate(PAGES.PROJECTS);
          getProjects().then(projects => {
            setProjects(projects);
          });
        });
      },
    },
  ];
  const items: MenuItem[] = [
    {
      label: 'Optionen',
      items:
        project?.rights?.find(right => right === rightKey.uploadFiles) ||
        project?.rights?.find(right => right === rightKey.root)
          ? adminOptions
          : UserOptions,
    },
  ];
  return (
    <div className="flex justify-content-center">
      <button className="noButton" onClick={event => menuRight.current?.toggle(event)}>
      <DefaultButton icon='octicon:kebab-horizontal-16' text='Bearbeiten'></DefaultButton>
      </button>
      <Menu model={items} popup ref={menuRight} id="popup_menu_right" popupAlignment="right" />
    </div>
  );
}
