import { useContext, useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { useParams } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import DefaultButton from '../../components/button';
import { TreeSelect } from 'primereact/treeselect';
import { FileUploadTypes, downloadFolders, postFile } from '../../func/auth/apiconnect';
import { TreeNode } from 'primereact/treenode';
import { FolderSelect } from './folder-select';
import ToastContext from '../../func/context/toastContext';
interface CreateFolderInterface {
  visible: boolean;
  onVisibleChange: (change: boolean) => void;
  onSuccessfulCreate: () => void;
}

export function CreateFolder(Params: CreateFolderInterface) {
  const [folderName, setFolderName] = useState<string>('');
  const [selectedFolder, setSelectedFolder] = useState<string>();
  const toastContext = useContext(ToastContext);
  const params = useParams();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setFolderName('');
  }, [Params.visible]);
  async function createFolder() {
    setLoading(true);
    let upload = await postFile({
      projectId: parseInt(params.projectId ?? '0'),
      fileName: folderName,
      groupIds: [],
      type: FileUploadTypes.Folder.toString(),
      parent: selectedFolder ? parseInt(selectedFolder) : 0,
      confirmationRequired: false,
      file: undefined,
    }).then(response => {
      setLoading(false);
      Params.onSuccessfulCreate();
      toastContext.showToast({content: 'Ordner erstellt', sevety: 'success'});
      setFolderName('');
    });
  }

  return (
    <div className="card flex justify-content-center">
      <Dialog
        draggable={false}
        header="Ordner erstellen"
        visible={Params.visible}
        style={{ width: '50vw' }}
        onHide={() => Params?.onVisibleChange(false)}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ padding: '10px' }}>
            <FolderSelect onSelect={folderID => setSelectedFolder(folderID)}></FolderSelect>
          </div>
          <p>/</p>
          <div style={{ padding: '10px' }}>
            <InputText value={folderName} onChange={e => setFolderName(e.target.value)}></InputText>
          </div>
        </div>
        <DefaultButton
          action={() => {
            createFolder();
          }}
          text="Erstellen"
          loading={loading}
        ></DefaultButton>
      </Dialog>
    </div>
  );
}
