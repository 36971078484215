import { Icon } from '@iconify/react';
import './project.css';
import NotificationBell from '../notification/notificationBell';
import Logo from '../generic/logo';
import { Link } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { ProjectContext } from '../../func/context/projectContext';
import Spacer from '../generic/spacer';
import { getProjectImage } from '../../func/auth/apiconnect';
import { ProjectLogo } from './project-logo';
import ProjectFolded from './project-folded';
type projectLogo = {
  imageUrl: string;
};
export default function ProjectOverview() {
  const { projects, setProjects } = useContext(ProjectContext);
  const [projectLogos, setProjectLogos] = useState<projectLogo[]>();
  useEffect(() => {
    projects?.forEach(project => {
      console.log(project);
      getProjectImage(project.imageUrl).then(logo => {
        if(!logo) return;
        let _projectLogos = projectLogos;
        if (!_projectLogos) _projectLogos = [{ imageUrl: URL.createObjectURL(logo) }];
        _projectLogos.push({ imageUrl: URL.createObjectURL(logo) });
        console.log(logo);
      });
    });
  }, projectLogos);
  if (projects)
    return (
      <>
        {projects
          .filter(project => project.projectActivated === true)
          .map(project => (
            <ProjectFolded project={project}></ProjectFolded>
          ))}
        {projects.filter(project => project.projectActivated === false).length > 0 && (
          <div style={{ paddingTop: '15px' }}>
            <span style={{ color: 'gray', fontSize: '20px' }}>Inaktive Projekte</span>
          </div>
        )}
        {projects
          .filter(project => project.projectActivated === false)
          .map(project => (
            <ProjectFolded project={project}></ProjectFolded>
          ))}
      </>
    );

  return <div className="CompanyDetailsShaddow company gradient" style={{ height: '96px' }}></div>;
}
