import { useEffect, useState } from 'react';
import Logo from '../generic/logo';
import { getCompanyImage } from '../../func/auth/apiconnect';

export function CompanyLogo({ logoId = '', HeightPx = 0 }) {
  const [logo, setLogo] = useState<string>();
  useEffect(() => {
    getCompanyImage(logoId).then(image => {
      if (!image) return;
      setLogo(URL.createObjectURL(image));
    });
  }, [logoId]);
  if (!logo) return (<></>);
  return <Logo HeightInPx={HeightPx} asyncLogo={logo.length ? false : true} LogoURL={logo}></Logo>;
}
