import { useContext } from 'react';
import DefaultButton from '../../components/button';
import MainComponent from '../../components/page/main-component';
import ProjectOverview from '../../components/project/project';
import { CompanyContext } from '../../func/context/companyContext';

export default function ProjectMain() {
  let { companys } = useContext(CompanyContext);
  return (
    <MainComponent
      pageContent={
        <>
          {companys?.length ? (
            <DefaultButton text="Neues Projekt" url={'/project/new/edit'}></DefaultButton>
          ) : (
            <DefaultButton
              text="Neues Projekt"
              action={() => {
                alert('Bitte zuerst eine Firma erstellen');
              }}
            ></DefaultButton>
          )}
          <ProjectOverview></ProjectOverview>
        </>
      }
    ></MainComponent>
  );
}
