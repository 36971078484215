import { Icon } from '@iconify/react';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { useContext, useEffect, useState } from 'react';
import './chat-window.css';
import { MultiSelect, MultiSelectChangeEvent } from 'primereact/multiselect';
import GroupSelection from '../group/group-selection';
import { InputTextarea } from 'primereact/inputtextarea';
import { getMessages, sendMessage } from '../../func/auth/apiconnect';
import { useNavigate, useParams } from 'react-router-dom';
import ChatBubble from './chat-bubble';
import { Project, ProjectContext, rightKey } from '../../func/context/projectContext';
import ToastContext, { toastContext } from '../../func/context/toastContext';
interface Messages {
  Created: string;
  id: number;
  message: string;
  ownUserId: number;
  User: {
    username: string;
    id: number;
  };
}
export default function ChatWindow() {
  const { projects, setProjects } = useContext(ProjectContext);
  const [project, setProject] = useState<Project>();
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();
  const [selectedGroupIds, setSelectedGroupIds] = useState<number[]>();
  const [message, setMessage] = useState('');
  const toastContext = useContext(ToastContext);
  const [messages, setMessages] = useState<Messages[]>([]);
  const params = useParams();
  useEffect(() => {
    downloadMessages();
    if (window.location.href.indexOf('notification') > -1) {
      setVisible(true);
    }
  }, []);
  useEffect(() => {
    setProject(projects?.find(project => project.id === parseInt(params.projectId ?? '0')));
    console.log(projects)
  }, [projects]);
  async function downloadMessages() {
    getMessages(parseInt(params.projectId ?? '0')).then(messages => {
      setMessages([...messages].sort((a, b) => a.id - b.id));
    });
  }
  async function postMessage() {
    sendMessage(parseInt(params.projectId ?? '0'), message, selectedGroupIds ?? []).then(() => {
      downloadMessages();
      toastContext.showToast({ content: 'Nachricht gesendet', sevety: 'success' });
      setMessage('');
    });
  }
  return (
    <>
      <div style={{ position: 'fixed', bottom: '20px', right: '20px' }}>
        <div
          style={{
            display: 'inline-flex',
            justifyContent: 'center',
            backgroundColor: '#005c60',
            borderRadius: '50%',
            width: '45px',
            height: '45px',
            alignItems: 'center',
          }}
        >
          <Icon
            fontSize={30}
            color="white"
            icon={'bx:chat'}
            onClick={() => setVisible(true)}
          ></Icon>
        </div>
      </div>
      <Dialog
        header="Chat"
        visible={visible}
        position="bottom-right"
        style={{ width: '50vw' }}
        onHide={() => {
          setVisible(false);
          navigate('/project/' + params.projectId);
        }}
        footer={
          (project?.rights?.find(right => right === rightKey.sendNotification) ||
            project?.rights?.find(right => right === rightKey.root)) && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <GroupSelection
                onSelect={function (groupIds: number[]): void {
                  setSelectedGroupIds(groupIds);
                }}
              ></GroupSelection>
              <InputTextarea
                style={{ width: '100%' }}
                value={message}
                rows={1}
                onChange={e => setMessage(e.target.value)}
                placeholder="Schreibe eine Nachricht"
              ></InputTextarea>
              <div className={'chatMessageButton'} onClick={() => postMessage()}>
                <Icon icon={'bi:send-fill'}></Icon>
              </div>
            </div>
          )
        }
        draggable={false}
        resizable={false}
      >
        {messages.map(message => {
          return (
            <ChatBubble
              Name={message?.User?.username??"Administrator"}
              isOwnMessage={message.ownUserId === message?.User?.id}
              message={message.message}
              time={message.Created}
            ></ChatBubble>
          );
        })}
        {messages.length === 0 && <p>Noch keine Nachrichten</p>}
      </Dialog>
    </>
  );
}
