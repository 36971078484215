import { Icon } from '@iconify/react';
import { Menu } from 'primereact/menu';
import { MenuItem } from 'primereact/menuitem';
import { CustomToast, CustomToastRef } from '../generic/toast';
import { useContext, useRef } from 'react';
import { getCompanys, removeCompany } from '../../func/auth/apiconnect';
import { useNavigate, useParams } from 'react-router-dom';
import { CompanyContext } from '../../func/context/companyContext';
import ToastContext from '../../func/context/toastContext';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import DefaultButton from '../button';

export default function CompanySettings({ onEditMode = () => { } }) {
  const menuRight = useRef<Menu>(null);
  const params = useParams();
  const navigate = useNavigate();
  const { companys, setCompanys } = useContext(CompanyContext);
  const confirm1 = () => {
    confirmDialog({
      message: 'Die Firma wird unwiderruflich gelöscht.',
      header: 'Bestätigen',
      acceptLabel: 'Ja',
      rejectLabel: 'Nein',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        removeCompany(params.companyId).then(() => {
          toastContext.showToast({
            content: 'Firma gelöscht',
            sevety: 'success',
          });

          getCompanys().then(_company => {
            setCompanys(_company);
            navigate('/companys');
          });
        });
      },
      reject: () => { },
    });
  };
  const toastContext = useContext(ToastContext);
  const items: MenuItem[] = [
    {
      label: 'Optionen',
      items: [
        {
          label: 'Bearbeiten',
          icon: (
            <>
              <Icon className="primaryFontColor" icon={'material-symbols:edit'}></Icon>
              <span style={{ paddingRight: '5px' }}></span>
            </>
          ),
          command: () => {
            onEditMode();
          },
        },
        {
          style: { backgroundColor: '#ff060636', borderRadius: '5px' },
          label: 'Löschen',
          icon: (
            <>
              <Icon className="primaryFontColor" icon={'mdi:trash-outline'}></Icon>
              <span style={{ paddingRight: '5px' }}></span>
            </>
          ),
          command: () => {
            confirm1();
          },
        },
      ],
    },
  ];
  return (
    <>
      <div className="flex justify-content-center">
        <button className="noButton" onClick={event => menuRight.current?.toggle(event)}>
        <DefaultButton icon='octicon:kebab-horizontal-16' text='Bearbeiten'></DefaultButton>
        </button>
        <Menu model={items} popup ref={menuRight} id="popup_menu_right" popupAlignment="right" />
      </div>
      <ConfirmDialog />
    </>
  );
}
