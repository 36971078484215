import * as OpenPGP from 'openpgp';
export async function encryptFile(file: File, password?: string): Promise<Uint8Array> {
  const message = await OpenPGP.createMessage({
    binary: new Uint8Array(await file.arrayBuffer()),
    filename: file.name,
  });
  const encrypted = await OpenPGP.encrypt({
    message,
    passwords: ['1234'],
    format: 'binary', // don't ASCII armor (for Uint8Array output)
  });
  return encrypted as Uint8Array;
}
export async function decryptFile(file: File, password?: string): Promise<Uint8Array> {
  const message = await OpenPGP.readMessage({
    binaryMessage: new Uint8Array(await file.arrayBuffer()),
  });
  if (!password) throw new Error('No password provided')
  const decrypted = await OpenPGP.decrypt({
    message,
    passwords: [password],
    format: 'binary', // don't ASCII armor (for Uint8Array input)
  });
  return decrypted.data as Uint8Array;
}
export async function encryptFileBlob(
  fileBlob: Blob,
  fileName: string,
  password?: string,
): Promise<Blob> {
  const message = await OpenPGP.createMessage({
    binary: new Uint8Array(await fileBlob.arrayBuffer()),
    filename: fileName,
  });
  const encrypted = await OpenPGP.encrypt({
    message,
    passwords: ['1234'],
    format: 'binary', // don't ASCII armor (for Uint8Array output)
  });
  return new Blob([encrypted as Uint8Array], { type: fileBlob.type });
}

