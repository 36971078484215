import { Icon } from '@iconify/react';
import { TreeTable } from 'primereact/treetable';
import { Column, ColumnEditorOptions } from 'primereact/column';

import './project-details.css';
import { useContext, useEffect, useState } from 'react';
import { TreeNode } from 'primereact/treenode';
import ProjectExpand from '../../components/project/project-expand';
import MainComponent from '../../components/page/main-component';
import DefaultButton from '../../components/button';
import { PAGES } from '../../enum/pages';
import { useNavigate, useParams } from 'react-router-dom';
import { ApiFile, FileUploadTypes, changeFolderName, getFiles } from '../../func/auth/apiconnect';
import { CreateFolder } from '../files/create-folder';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import ChatWindow from '../../components/chat/chat-window';
import { Project, ProjectContext, rightKey } from '../../func/context/projectContext';
import { Group } from './project-groups';
export interface SimpleFile {
  id: number;
  fileName: string;
  fileType: number;
  fileExtension: string;
  parent: number;
  Project: Project;
  Groups?: Group[];
}
export default function ProjectDetails() {
  const [nodes, setNodes] = useState<TreeNode[]>([]);
  const [createFolderVisible, setCreateFolderVisible] = useState<boolean>(false);
  const { projects, setProjects } = useContext(ProjectContext);
  const [project, setProject] = useState<Project>();
  const [allFiles, setAllFiles] = useState<SimpleFile[]>([]);
  const navigate = useNavigate();
  const [globalFilter, setGlobalFilter] = useState<string>();
  let params = useParams();
  useEffect(() => {
    setProject(projects?.find(project => project.id === parseInt(params.projectId ?? '0')));
  }, [projects]);
  useEffect(() => {
    downloadFiles();
  }, []);
  function loadSubTree(treeId: number) {
    let files = allFiles.filter(file => file.parent === treeId);
    let nodes: TreeNode[] = files.map((file: SimpleFile) => {
      return {
        key: file.id.toString(),
        label: file.fileName,
        data: {
          label: file.fileName,
          fileExtension: file.fileExtension,
          fileType: file.fileType,
          setEditing: false,
        },
        children: file.fileType === FileUploadTypes.Folder ? loadSubTree(file.id) : [],
      } as TreeNode;
    });
    return nodes;
  }
  async function downloadFiles() {
    let files = await getFiles(parseInt(params.projectId ?? '0'));
    setAllFiles(files);
  }
  function setEditMode(nodeId: number) {
    let node = nodes.find(node => node.key === nodeId.toString());
    if (!node) return;
    node.data.setEditing = true;
    setNodes([...nodes]);
  }
  useEffect(() => {
    setNodes(loadSubTree(0));
  }, [allFiles]);
  const actionTemplate = (rowData: any) => {
    if (rowData.data.fileType === FileUploadTypes.File) {
      return <></>;
    }
    return (
      <div className="flex flex-wrap gap-2 " style={{ display: 'flex', justifyContent: 'end' }}>
        <div style={{ padding: '10px', borderRadius: '45%' }}>
          {!rowData.data.setEditing ? (
            <Icon icon={'mingcute:edit-line'} onClick={() => setEditMode(rowData.key)}>
              Bearbeiten
            </Icon>
          ) : (
            <div className={'folderContextButton'}>
              <DefaultButton
                text="Speichern"
                action={() => {
                  changeFolderName(rowData.key, rowData.data.label).then(response => {
                    downloadFiles();
                  });
                }}
              ></DefaultButton>
              <DefaultButton
                classes="deletedButton"
                action={() => {
                  delete rowData.key;
                }}
                text="Löschen"
              ></DefaultButton>
            </div>
          )}
        </div>
      </div>
    );
  };
  function onSelect(e: any) {
    if (e?.node?.data?.fileType === FileUploadTypes.File) {
      navigate('/view-file/' + e?.node?.key);
    }
  }
  function formatBody(rowData: any) {
    return (
      <div style={{ display: 'inline-flex', alignItems: 'center' }}>
        {rowData.data.fileExtension === 'image/png' ? (
          <Icon icon={'material-symbols:image-outline'}></Icon>
        ) : rowData.data.fileType === FileUploadTypes.Folder ? (
          <Icon icon={'material-symbols:folder'}></Icon>
        ) : (
          <Icon icon={'fontisto:ellipse'}></Icon>
        )}
        {!rowData.data.setEditing ? (
          <span style={{ paddingLeft: '5px' }}> {rowData.data.label}</span>
        ) : (
          <InputText
            value={rowData.data.label}
            onChange={e => {
              rowData.data.label = e.target.value;
              setNodes([...nodes]);
            }}
          ></InputText>
        )}
      </div>
    );
  }
  return (
    <MainComponent
      pageContent={
        <>
          <ProjectExpand />
          {(project?.rights?.find(
            right =>
              right === rightKey.uploadFiles) ||
              project?.rights?.find(right => right === rightKey.root)
          ) ? (
            <>
              <div className="uploadFileButton">
                <DefaultButton
                  url={'/upload-files/' + params.projectId + '/NEW'}
                  text="Datei hochladen"
                ></DefaultButton>
                <DefaultButton
                  action={() => setCreateFolderVisible(true)}
                  text="Ordner erstellen"
                ></DefaultButton>
              </div>
            </>
          ) : null}
          <div style={{ paddingTop: '25px' }}>
            <TreeTable
              emptyMessage="Keine Dateien gefunden"
              selectionMode="single"
              onSelect={onSelect}
              value={nodes}
              globalFilter={globalFilter}
              tableStyle={{ minWidth: '50rem' }}
            >
              <Column
                body={formatBody}
                filter
                filterPlaceholder="Suchen"
                field="label"
                header="Name"
                expander
                sortable
              ></Column>

              <Column body={actionTemplate} headerClassName="w-10rem" />
            </TreeTable>
          </div>
          <ChatWindow></ChatWindow>
          <CreateFolder
            onSuccessfulCreate={() => {
              downloadFiles();
              setCreateFolderVisible(false);
            }}
            onVisibleChange={(change: boolean) => {
              setCreateFolderVisible(change);
            }}
            visible={createFolderVisible}
          ></CreateFolder>
        </>
      }
    ></MainComponent>
  );
}
