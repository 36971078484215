import { Payment, PaymentState } from '../../func/context/projectContext';

export const getFormattedDate = (date: Date | undefined) => {
  const currentDate = new Date(date ?? 0);
  const formattedDate = currentDate.toLocaleDateString('de-de'); // 'en-GB' for the dd/mm/yyyy format

  return formattedDate;
};

// Get the next activation date from the current date
export const getNextActivationDate = (payments: Payment[]): Date | null => {
  const currentDate = new Date();
  const futurePayments = payments
    .filter(
      payment => payment.from && new Date(payment.from).getTime() > new Date(currentDate).getTime(),
    )
    .sort((a, b) => new Date(a.from!).getTime() - new Date(b.from!).getTime()); // Sort payments by 'from' date
  return futurePayments.length > 0 ? futurePayments[0].from! : null;
};

export const isCurrentDateInRange = (payment: Payment): boolean | undefined => {
  const currentDate = new Date();
  return payment.from && payment.to && currentDate >= payment.from && currentDate <= payment.to;
};
export const isCurrentDateInRangeInArray = (payments: Payment[]): boolean => {
  const currentDate = new Date();
  let successfullpayment = payments.filter(payment => payment.state === PaymentState.successful);
  for (const payment of successfullpayment) {
    if (
      payment.from &&
      payment.to &&
      new Date(currentDate).getTime() >= new Date(payment.from).getTime() &&
      new Date(currentDate).getTime() <= new Date(payment.to).getTime()
    ) {
      return true;
    }
  }

  return false;
};
export const getCurrentActivationEndDate = (payments: Payment[] | undefined): Date | null => {
  const currentDate = new Date();
  if (!payments) return null;
  for (const payment of payments) {
    if (
      payment.from &&
      payment.to &&
      new Date(currentDate).getTime() >= new Date(payment.from).getTime() &&
      new Date(currentDate).getTime() <= new Date(payment.to).getTime()
    ) {
      return payment.to;
    }
  }

  return null; // Return null if no active payment is found
};
// Generate an array of all dates within a date range
type DateRange = { from: Date; to: Date };
const getDatesInRange = (range: DateRange): Date[] => {
  const dates: Date[] = [];
  let currentDate = new Date(range.from);

  while (currentDate <= range.to!) {
    dates.push(new Date(currentDate));
    currentDate.setDate(new Date(currentDate).getDate() + 1);
  }

  return dates;
};
// Generate an array of all dates within the ranges of all payments
export const getAllDatesInPaymentRanges = (
  payments: Payment[] | [],
  filter: PaymentState | undefined,
): Date[] => {
  if (!payments) return [];
  const allDates: Date[] = [];
  for (const payment of payments) {
    if (payment.from && payment.to) {
      if (filter && filter !== payment.state) {
        continue;
      }
      const datesInRange = getDatesInRange({
        from: new Date(payment.from),
        to: new Date(payment.to),
      });
      allDates.push(...datesInRange);
    }
  }

  // Remove duplicate dates by converting the array to a Set and back to an array
  const uniqueDates = Array.from(new Set(allDates));
  return uniqueDates;
};
