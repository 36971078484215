import './logo.css';
export default function Logo({ LogoURL = '', HeightInPx = 215, asyncLogo = false, classNames='' }) {
  const WidthInPx = HeightInPx / 0.5625;
  return (
    <div
      className={classNames+` DetailsImg ${asyncLogo ? 'Loadinggradient' : ''}`}
      style={{
        height: HeightInPx + 'px',
        width: WidthInPx + 'px',
        backgroundImage: LogoURL ? 'url(' + LogoURL + ')' : asyncLogo ? '' : undefined,
        backgroundColor: asyncLogo && !LogoURL ? 'white' : 'unset',
        backgroundSize: asyncLogo ? 'cover' : 'contain',
      }}
    ></div>
  );
}
