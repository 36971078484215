import { useEffect, useState } from 'react';
import { Calendar } from 'primereact/calendar';
import { addLocale } from 'primereact/api';
import DefaultButton from '../button';
import { useParams } from 'react-router-dom';
import { getActivationsByProjectId, startPayment } from '../../func/auth/apiconnect';
import { Nullable } from 'primereact/ts-helpers';
import { SelectButton } from 'primereact/selectbutton';
import { Payment, PaymentState } from '../../func/context/projectContext';
import { getAllDatesInPaymentRanges } from '../generic/date';
export default function ProjectActivation() {
  const [dates, setDates] = useState<Nullable<(Date | null)[]>>(null);
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const options: string[] = ['Bei Aktivierung', 'Eigenes Datum'];
  const [activatedDates, setActivatedDates] = useState<Payment[]>();
  const [sendNotifications, setSendNotifications] = useState(options[0]);
  const [sendNotificationsDate, setSendNotificationsDate] = useState<Nullable<Date>>(null);
  function countDatesBetween(startDate: Date | null, endDate: Date | null): number {
    if (endDate === null || startDate === null) return 0;
    // Set the start and end dates without the time component
    const start = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
    const end = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());

    // Calculate the difference in milliseconds
    const millisecondsPerDay = 24 * 60 * 60 * 1000;
    const differenceInMilliseconds = Math.abs(end.getTime() - start.getTime());

    // Calculate the number of days between the two dates
    const daysBetween = Math.floor(differenceInMilliseconds / millisecondsPerDay);

    return daysBetween + 1;
  }
  useEffect(() => {
    getActivationsByProjectId(parseInt(params.projectId ?? '0')).then((dates: Payment[]) => {
      setActivatedDates(dates);
    });
  }, []);
  function formatDate(date: Date | Date[] | (Date | null)[] | string | null): string {
    if (date === null) return '';
    if (Array.isArray(date)) {
      return date.map(d => formatDate(d)).join(' - ');
    } else if (typeof date === 'string') {
      // Handle date string
      return formatDate(new Date(date));
    }
    // Format the date string with German locale
    var formattedDate = date.toLocaleDateString('de-DE', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
    return formattedDate;
  }
  addLocale('de', {
    firstDayOfWeek: 1,
    dayNames: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
    dayNamesShort: ['Son', 'Mon', 'Die', 'Mit', 'Don', 'Fre', 'Sam'],
    dayNamesMin: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
    monthNames: [
      'Januar',
      'Februar',
      'März',
      'April',
      'Mai',
      'Juni',
      'Juli',
      'August',
      'September',
      'Oktober',
      'November',
      'Dezember',
    ],
    monthNamesShort: [
      'Jan',
      'Feb',
      'Mär',
      'Apr',
      'Mai',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Okt',
      'Nov',
      'Dez',
    ],
    today: 'Heute',
    clear: 'Löschen',
  });
  async function postPayment() {
    setLoading(true);
    if (dates === null || dates === undefined) return;
    if (dates.length === 0) return;
    if (dates[0] === null || dates[1] === null) return;
    let response = await startPayment(
      parseInt(params.projectId ?? '0'),
      dates[0].getTime(),
      dates[1].getTime(),
      sendNotificationsDate?.getTime() ?? new Date().getTime(),
    );
    setLoading(false);
    window.location.href = response.url;
  }
  return (
    <>
      <Calendar
        selectionMode="range"
        minDate={new Date()}
        numberOfMonths={2}
        value={dates}
        
        disabledDates={getAllDatesInPaymentRanges(activatedDates ?? [], PaymentState.successful)}
        onChange={(e: any) => setDates(e.value ?? new Date())}
        inline
        locale="de"
      />
      <>
        <div hidden={dates == null ? true : false}>
          <span>Ausgewählter Zeitraum: {formatDate(dates ?? null)}</span>
          <span>{Array.isArray(dates) ? countDatesBetween(dates[0], dates[1]) : null}</span>
          <p>
            <label htmlFor="item">Einladungen versenden: </label>
          </p>
          <SelectButton
            value={sendNotifications}
            onChange={e => setSendNotifications(e.value)}
            options={options}
          />
          <br></br>
          <div
            style={{
              display: sendNotifications === options[1] ? 'block' : 'none',
              paddingBottom: '15px',
            }}
          >
            <Calendar
              showTime
              dateFormat=""
              placeholder="Datum auswählen"
              value={sendNotificationsDate}
              onChange={e => setSendNotificationsDate(e.value)}
            ></Calendar>
          </div>
          <DefaultButton
            loading={loading}
            icon=""
            action={() => postPayment()}
            text={
              (Array.isArray(dates) ? countDatesBetween(dates[0], dates[1]) : '') + ' Tage Buchen'
            }
          ></DefaultButton>
        </div>
      </>
    </>
  );
}
