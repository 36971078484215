import './App.css';
import './fonts/fonts.css';
//theme
import 'primereact/resources/themes/lara-light-indigo/theme.css';

//core
import 'primereact/resources/primereact.min.css';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Dashboard from './pages/dashboard';
import ProjectDetails from './pages/project/project-details';
import ProjectMain from './pages/project/project-main';
import ErrorPage from './components/page/error';
import { PAGES } from './enum/pages';
import EditProject from './pages/project/project-edit';
import CompanyOverviewPage from './pages/company/company-overview';
import CompanyDetailsPage from './pages/company/company-details';
import EditCompany from './pages/company/company-edit';
import ProjectGroupEditPage from './pages/project/project-groups';
import { AuthProvider, useAuth } from 'react-oidc-context';
import { User } from 'oidc-client-ts';
import { Data } from './func/auth/events';
import { setToken } from './func/auth/apiconnect';
import { CompanyContext, DataProvider } from './func/context/companyContext';
import { ProjectDataProvider } from './func/context/projectContext';
import { UploadFile } from './pages/files/upload-file';
import { ViewFile } from './pages/files/view-file';
import ToastContext, { toastContext } from './func/context/toastContext';
import { Toast } from 'primereact/toast';
import ProjectShare from './pages/project/project-share';
const router = createBrowserRouter([
  {
    path: PAGES.DASHBOARD,
    element: <Dashboard></Dashboard>,
    errorElement: <ErrorPage></ErrorPage>,
  },
  {
    path: PAGES.PROJECTS,
    element: <ProjectMain />,
  },
  {
    path: 'project/:projectId',
    element: <ProjectDetails></ProjectDetails>,
  },
  {
    path: 'project/:projectId/notification',
    element: <ProjectDetails></ProjectDetails>,
  },
  {
    path: PAGES.COMPANYS,
    element: <CompanyOverviewPage></CompanyOverviewPage>,
  },
  {
    path: PAGES.EDITPROJECT,
    element: <EditProject></EditProject>,
  },
  {
    path: PAGES.COMPANYOVERVIEW,
    element: <CompanyDetailsPage></CompanyDetailsPage>,
  },
  {
    path: PAGES.EDITCompany,
    element: <EditCompany></EditCompany>,
  },
  {
    path: PAGES.EDITProjectGroups,
    element: <ProjectGroupEditPage></ProjectGroupEditPage>,
  },
  {
    path: PAGES.UPLOADFILES,
    element: <UploadFile></UploadFile>,
  },
  {
    path: PAGES.ViewFiles,
    element: <ViewFile></ViewFile>,
  },
  {
    path: PAGES.ShareProject,
    element: <ProjectShare></ProjectShare>,
  },
]);
export function RequireAuth({ children }: { children: JSX.Element }) {
  const auth = useAuth();
  if (auth.isAuthenticated) {
    setToken(auth.user?.access_token);
    //alert(auth.user?.access_token)
    console.log(auth.user?.access_token);
    /* axiosInstance.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${auth.user?.access_token}`; */
    return <>{children}</>;
  }
  if (!auth.error && auth.activeNavigator === undefined && !auth.isLoading) {
    localStorage.clear();
    console.info('Initiating signinRedirect()');
    auth.signinRedirect();
    return <></>;
  }
  return <></>;
}
function App() {
  Data();
  return (
    <AuthProvider
      client_id="v3-mach-web"
      redirect_uri={window.location.href}
      authority="https://auth.mach.app/auth/realms/mach-app"
      revokeTokensOnSignout={true}
      onSigninCallback={(_user: User | void) => {
        window.history.replaceState({}, document.title, window.location.pathname);
      }}
    >
      <ToastContext.Provider value={toastContext}>
        <RequireAuth
          children={
            <ProjectDataProvider
              children={<DataProvider children={<RouterProvider router={router} />}></DataProvider>}
            ></ProjectDataProvider>
          }
        ></RequireAuth>
      </ToastContext.Provider>
    </AuthProvider>
  );
}

export default App;
