import { InputText } from 'primereact/inputtext';
import CompanyExpand from '../../components/company/company-expand';
import Spacer from '../../components/generic/spacer';
import MainComponent from '../../components/page/main-component';
import { DataTable, DataTableValueArray } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Icon } from '@iconify/react';
import { useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Company, CompanyContext, Employees } from '../../func/context/companyContext';
import {
  addEmployee,
  getCompanys,
  getEmployees,
  getProjects,
  removeEmployee,
} from '../../func/auth/apiconnect';
import { useAuth } from 'react-oidc-context';
import { Project, ProjectContext } from '../../func/context/projectContext';
import ProjectExpand from '../../components/project/project-expand';
import ToastContext from '../../func/context/toastContext';

export default function EditProject() {
  const { showToast } = useContext(ToastContext);
  const params = useParams();

  function loadPageInformations() {
    getProjects().then(projects => {
      let project: Project = projects.find(
        (project: Project) => project.id === parseInt(params.projectId ?? '0'),
      );
      if (!project) return;
    });
  }
  useEffect(() => {
    loadPageInformations();
  }, []);
  return (
    <MainComponent
      pageContent={
        <>
          <ProjectExpand editMode={true}></ProjectExpand>
        </>
      }
    ></MainComponent>
  );
}
