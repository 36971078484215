import { Icon } from '@iconify/react';
import '../fonts/fonts.css';
import './menuitem.css';
import { Link } from 'react-router-dom';
import { handleScrollToTop } from '../components/generic/scroll';
export default function MenuItem({ text = '', link = '', icon = '', hightlighted = false }) {
  const classes = 'menuitem ' + (hightlighted ? 'current' : '');
  return (
    <Link onClick={() => handleScrollToTop()} className={classes} to={link}>
      <div className="menuClass">
        <Icon className="primaryFontColor" icon={icon}></Icon>
        <span>{text}</span>
      </div>
    </Link>
  );
}
