import React, { useRef, useImperativeHandle, forwardRef } from 'react';
import { Button } from 'primereact/button';
import { Toast, ToastMessage } from 'primereact/toast';
type CustomToastProps = {
  // Define the props for the child component
};
export type ToastContent = {
  content: string;
  sevety: ToastMessage['severity'];
};
export type CustomToastRef = {
  showToast: (ToastContent: ToastContent) => void;
};
export const CustomToast = forwardRef<CustomToastRef, CustomToastProps>((props, ref) => {
  useImperativeHandle(ref, () => ({
    showToast(ToastContent: ToastContent) {
      toast.current?.show({
        severity: ToastContent.sevety,
        summary: ToastContent.sevety === 'success' ? 'Erfolg' : 'Fehler',
        detail: ToastContent.content,
        life: 3000,
      });
    },
  }));

  const toast = useRef<Toast>(null);

  return (
    <div className="card flex justify-content-center">
      <Toast className="p-button-danger" position="bottom-right" ref={toast} />
    </div>
  );
});
