import { useContext, useEffect, useState } from 'react';
import CompanyOverview from '../components/company/company';
import Spacer from '../components/generic/spacer';
import MainComponent from '../components/page/main-component';
import ProjectOverview from '../components/project/project';
import { ProjectContext } from '../func/context/projectContext';
import { CompanyContext } from '../func/context/companyContext';
import { useAuth } from 'react-oidc-context';
import { getUser } from '../func/auth/apiconnect';
import { User } from '../components/profile/profile';
import { useParams } from 'react-router-dom';

export default function Dashboard() {
  const [user, setUser] = useState<User>();
  useEffect(() => {
    const params:any = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop as any),
    });
    if(params?.joinProject){
      alert(params?.joinProject)
    }
    getUser().then((_user: User) => {
      setUser(_user);
      if (!_user.firstname?.length) {
        setUser({ firstname: token.user?.profile.preferred_username });
      }
      if (!user?.firstname?.length) {
        setUser({ firstname: 'bei MACH!' });
      }
    });
  }, []);
  const { projects } = useContext(ProjectContext);
  const { companys } = useContext(CompanyContext);
  const token = useAuth();
  return (
    <MainComponent
      pageContent={
        <>
          <span style={{ fontSize: '24px', fontFamily: 'PoppinsSemiBold' }}>
            Willkommen, {token?.user?.profile.preferred_username}
          </span>
          {projects?.length ? (
            <>
              <div style={{ paddingTop: '15px' }}>
                <span style={{ color: 'gray', fontSize: '20px' }}>Laufende Projekte</span>
              </div>
              <ProjectOverview></ProjectOverview>
            </>
          ) : null}
          {companys?.length ? (
            <>
              <div style={{ paddingTop: '30px' }}>
                <span style={{ color: 'gray', fontSize: '20px' }}>Firmen</span>
              </div>
              <CompanyOverview></CompanyOverview>
            </>
          ) : null}
          {companys?.length || projects?.length ? null : (
            <div style={{ paddingTop: '30px' }}>
              <span style={{ color: 'gray', fontSize: '20px' }}>
                Keine Projekte oder Firmen vorhanden
              </span>
            </div>
          )}
        </>
      }
    ></MainComponent>
  );
}
