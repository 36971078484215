import DefaultButton from '../../components/button';
import CompanyOverview from '../../components/company/company';
import MainComponent from '../../components/page/main-component';

export default function CompanyOverviewPage() {
  return (
    <MainComponent
      pageContent={
        <>
          <DefaultButton text="Neue Firma" url={'/company/new/edit'}></DefaultButton>
          <CompanyOverview></CompanyOverview>
        </>
      }
    ></MainComponent>
  );
}
