import { Icon } from '@iconify/react';
import './user.css';
import { Toast } from 'primereact/toast';
import { Menu } from 'primereact/menu';
import { useRef, useState } from 'react';
import { MenuItem } from 'primereact/menuitem';
import { useAuth } from 'react-oidc-context';
import Profile from '../profile/profile';
export default function UserSettings() {
  const auth = useAuth();
  const [visible, setVisible] = useState(false);
  const menuRight = useRef<Menu>(null);
  const toast = useRef<Toast>(null);
  const items: MenuItem[] = [
    {
      label: 'Profil',
      icon: (
        <>
          <Icon className="primaryFontColor" icon={'iconamoon:profile-bold'}></Icon>
          <span style={{ paddingRight: '5px' }}></span>
        </>
      ),
      command: () => {
        setVisible(true);
      }
    },
    {
      label: 'Einstellungen',
      icon: (
        <>
          <Icon className="primaryFontColor" icon={'material-symbols:settings-outline'}></Icon>
          <span style={{ paddingRight: '5px' }}></span>
        </>
      ),
      command: () => {
        toast.current?.show({
          severity: 'warn',
          summary: 'Delete',
          detail: 'Data Deleted',
          life: 3000,
        });
      },
    },
    {
      label: 'Logout',
      icon: <Icon icon={'logout'}></Icon>,
      command: () => {
        auth.signoutRedirect({
          post_logout_redirect_uri: window.location.origin,
        });
      },
    },
  ];
  return (
    <>
      <div className="profileMenu">
        <button className="noButton" onClick={event => menuRight.current?.toggle(event)}>
          <img src="/logo.svg"></img>
        </button>
      </div>
      <Menu model={items} popup ref={menuRight} id="popup_menu_right" popupAlignment="right" />
      <Profile visible={visible} onVisibleChange={()=>setVisible(false)}></Profile>
    </>
  );
}
