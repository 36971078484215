import { TreeNode } from 'primereact/treenode';
import { useEffect, useState } from 'react';
import { downloadFolders } from '../../func/auth/apiconnect';
import { TreeSelect } from 'primereact/treeselect';
import { useParams } from 'react-router-dom';
import { Icon } from '@iconify/react';
interface Folder {
  fileName: string;
  parent: number;
  id: number;
}
interface FolderSelectInterface {
  onSelect: (folderId: string | undefined) => void;
  defaultFolderSelect?: string;
}
export function FolderSelect(FolderParams: FolderSelectInterface) {
  const params = useParams();
  const [folders, setFolders] = useState<TreeNode[]>([]);
  const [selectedFolder, setSelectedFolder] = useState<string>();
  const [defaultSelected, setDefaultSelected] = useState<string>();
  const [allFolders, setAllFolders] = useState<Folder[]>([]);
  function loadSubTree(treeId: number) {
    let folders = allFolders.filter(folder => folder.parent === treeId);
    let nodes: TreeNode[] = folders.map((folder: Folder) => {
      return {
        key: folder.id.toString(),
        label: folder.fileName,
        children: loadSubTree(folder.id),
      } as TreeNode;
    });
    if (treeId === 0) {
      return [
        {
          key: 0,
          label: 'Home',
          children: nodes,
        },
      ] as TreeNode[];
    }
    return nodes;
  }
  useEffect(() => {
    if (FolderParams.defaultFolderSelect) {
      setSelectedFolder(FolderParams.defaultFolderSelect);
      console.log('DEFAULT', FolderParams.defaultFolderSelect);
    }
  }, [FolderParams.defaultFolderSelect]);
  async function getFolders() {
    let folders = await downloadFolders(parseInt(params.projectId ?? '0'));
    setAllFolders(folders);
  }
  useEffect(() => {
    FolderParams.onSelect(selectedFolder);
  }, [selectedFolder]);
  useEffect(() => {
    setFolders(loadSubTree(0));
    setSelectedFolder('0');
  }, [allFolders]);
  useEffect(() => {
    getFolders();
  }, []);
  return (
    <>
      <TreeSelect
        value={selectedFolder}
        onChange={(e: any) => setSelectedFolder(e.value)}
        options={folders}
        selectionMode="single"
        className="md:w-20rem w-full"
        placeholder="Order auswählen"
      ></TreeSelect>
    </>
  );
}
