import React from 'react';
import './chat-bubble.css';

interface ChatBubbleInterface {
  message: string;
  isOwnMessage: boolean;
  Name: string;
  time: string;
}
const ChatBubble = (chatparams: ChatBubbleInterface) => {
  const chatBubbleClass = chatparams.isOwnMessage ? 'chat-bubble own-message' : 'chat-bubble';
  return (
    <div className={'outerChat ' + (chatparams.isOwnMessage ? 'own-message' : '')}>
      <div className={chatBubbleClass}>
        <p className="chatHeader">{chatparams.Name}</p>
        <p className="chatMessage">{chatparams.message}</p>
        <p className="chatTime">{chatparams.time}</p>
      </div>
    </div>
  );
};

export default ChatBubble;
