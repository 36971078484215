import { InputText } from 'primereact/inputtext';
import CompanyExpand from '../../components/company/company-expand';
import Spacer from '../../components/generic/spacer';
import MainComponent from '../../components/page/main-component';
import { DataTable, DataTableValueArray } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Icon } from '@iconify/react';
import { useContext, useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Company, CompanyContext, Employees } from '../../func/context/companyContext';
import { addEmployee, getCompanys, getEmployees, removeEmployee } from '../../func/auth/apiconnect';
import { useAuth } from 'react-oidc-context';
import { Button } from 'primereact/button';
import DefaultButton from '../../components/button';
import ToastContext from '../../func/context/toastContext';
import { toastContext } from '../../func/context/toastContext';
import verifyEmail from '../../components/generic/verifyEmail';

export default function EditCompany() {
  const toastContext = useContext(ToastContext);
  const auth = useAuth();
  const [employeeName, setEmployeeName] = useState('');
  const [companyEmployees, setCompanyEmployees] = useState<DataTableValueArray>();
  const params = useParams();
  async function removeEmployees(userId: number) {
    setCompanyEmployees(undefined);
    console.log(params.companyId);
    await removeEmployee(userId, parseInt(params.companyId ?? '0'));
    toastContext.showToast({
      content: 'Mitarbeiter wurde entfernt',
      sevety: 'success',
    });

    loadPageInformations();
  }
  async function addEmployeea() {
    setCompanyEmployees(undefined);
    if (!employeeName) {
      toastContext.showToast({
        content: 'E-Mail fehlt',
        sevety: 'error',
      });
      return;
    }
    if (!verifyEmail(employeeName)) {
      toastContext.showToast({
        content: 'E-Mail ist nicht korrekt',
        sevety: 'error',
      });
      return;
    }
    try {
      await addEmployee(employeeName, params.companyId);
    } catch (error) {
      toastContext.showToast({ content: JSON.stringify(error), sevety: 'error' });
      return;
    }

    toastContext.showToast({
      content: 'Mitarbeiter wurde hinzugefügt',
      sevety: 'success',
    });
    setEmployeeName('');
    loadPageInformations();
  }
  function loadPageInformations() {
    getCompanys().then(companys => {
      let company: Company = companys.find(
        (company: Company) => company.id === parseInt(params.companyId ?? '0'),
      );
      if (!company) return;
      getEmployees(company.id ?? 0).then(response => {
        let EmployeeList = response.Employees.map((employee: Employees) => {
          return {
            id: employee.id,
            name: employee.User.email,
            remove: employee.User.currentUser ? null : (
              <a
                href=" "
                onClick={e => {
                  e.preventDefault();
                  removeEmployees(employee.User.id);
                }}
              >
                <Icon icon={'mdi:remove'}></Icon>
              </a>
            ),
          };
        });
        setCompanyEmployees(EmployeeList);
      });
    });
  }
  useEffect(() => {
    loadPageInformations();
  }, []);
  return (
    <MainComponent
      pageContent={
        <>
          <CompanyExpand editMode={true}></CompanyExpand>
          {!params.companyId || params.companyId === 'new' ? null : (
            <>
              <div>
                <Spacer space={50}></Spacer>
                <span className="header">Mitarbeiter</span>
              </div>
              <Spacer space={30}></Spacer>

              <div
                className="flex flex-column gap-2"
                style={{ display: 'flex', flexDirection: 'row' }}
              >
                <span className="p-float-label">
                  <InputText
                    id="username"
                    value={employeeName}
                    onChange={e => setEmployeeName(e.target.value)}
                  />
                  <label htmlFor="username">E-Mail Adresse eingeben</label>
                </span>
                <div style={{ maxWidth: '150px', paddingLeft: '15px' }}>
                  <DefaultButton action={() => addEmployeea()} text="Speichern" />
                </div>
              </div>
              <Spacer space={30}></Spacer>
              <DataTable value={companyEmployees} tableStyle={{ minWidth: '50rem' }}>
                <Column field="name" header="Name"></Column>
                <Column field="remove"></Column>
              </DataTable>
            </>
          )}
        </>
      }
    ></MainComponent>
  );
}
