import { Icon } from '@iconify/react';
import './project-expand.css';
import Logo from '../generic/logo';
import ProjectSettings from '../settings/project';
import { useContext, useEffect, useRef, useState } from 'react';
import { useMountEffect } from 'primereact/hooks';
import { Messages } from 'primereact/messages';
import DefaultButton from '../button';
import { PAGES } from '../../enum/pages';
import ProjectActivation from './project-activation';
import { Dialog } from 'primereact/dialog';
import { Payment, Project, ProjectContext, rightKey } from '../../func/context/projectContext';
import { FileUpload } from 'primereact/fileupload';
import { CustomToast, CustomToastRef } from '../generic/toast';
import { compressImage } from '../generic/compress';
import {
  UpdateProject,
  getActivations,
  getActivationsByProjectId,
  getCompanys,
  getProjectImage,
  getProjects,
} from '../../func/auth/apiconnect';
import { useNavigate, useParams } from 'react-router-dom';
import { encryptFile } from '../generic/encrypt';
import { InputText } from 'primereact/inputtext';
import { customFile } from '../../pages/files/upload-file';
import { Dropdown } from 'primereact/dropdown';
import { Company, CompanyContext } from '../../func/context/companyContext';
import ToastContext, { toastContext } from '../../func/context/toastContext';
import {
  getNextActivationDate,
  getFormattedDate,
  isCurrentDateInRange,
  isCurrentDateInRangeInArray,
  getCurrentActivationEndDate,
} from '../generic/date';
import { act } from 'react-dom/test-utils';
export default function ProjectExpand({ editMode = false }) {
  const { projects, setProjects } = useContext(ProjectContext);

  const [imageBlob, setImageBlob] = useState('');
  const [modalVisibility, setModalVisibility] = useState(false);
  const msgs = useRef<Messages>(null);
  const [selectedCompany, setSelectedCompany] = useState<Company>();
  const [activations, setActivations] = useState<Payment[]>([]);
  const { companys } = useContext(CompanyContext);
  const params = useParams();
  const [project, setProject] = useState<Project>({
    id: 0,
    name: '',
    imageUrl: '',
    Company: { id: 0, name: '', logoUrl: '' },
  });
  const [loading, setLoading] = useState(false);
  const navigation = useNavigate();
  const toastContext = useContext(ToastContext);
  const fileUploadRef = useRef<FileUpload>(null);
  let showFalse = false;
  useEffect(() => {
    if (!params.projectId) {
      return;
    }
    const urlProjectId: number = parseInt(params.projectId);
    if (projects === null) return;
    const project = projects.find(singleProject => singleProject.id === urlProjectId);
    if (!project) return;
    setProject(project);
    if (project.imageUrl !== null) {
      getProjectImage(project.imageUrl).then(image => {
        if (!image) return;
        setImageBlob(URL.createObjectURL(image));
      });
    }
    getActivationsByProjectId(project.id).then(activations => {
      if (msgs === null || msgs.current === null) return;
      if (isCurrentDateInRangeInArray(activations))
        return msgs.current.show({
          sticky: true,
          severity: 'success',
          closable: false,
          content: (
            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between',
              }}
            >
              <div style={{ display: 'flex', alignSelf: 'center' }}>
                <span style={{ paddingRight: '2px' }}>Das Projekt ist aktiviert bis zum </span>
                {getFormattedDate(getCurrentActivationEndDate(activations) ?? new Date())}
              </div>

              <DefaultButton
                icon=""
                action={() => setModalVisibility(true)}
                text="Verlängern"
              ></DefaultButton>
            </div>
          ),
        });
      if (project.rights?.find(right => right === rightKey.root)) {
        msgs.current.show({
          sticky: true,
          severity: 'warn',
          closable: false,
          content: (
            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between',
              }}
            >
              <div style={{ display: 'flex', alignSelf: 'center' }}>
                Das Projekt ist nicht aktiviert{' '}
                {getNextActivationDate(activations) &&
                  `nächste Aktivierung am ${getFormattedDate(
                    getNextActivationDate(activations) ?? undefined,
                  )} `}
              </div>
              <DefaultButton
                icon=""
                action={() => setModalVisibility(true)}
                text="Aktivieren"
              ></DefaultButton>
            </div>
          ),
        });
      }
    });
  }, [projects]);

  async function setFileAsBlob() {
    if (!fileUploadRef.current?.getFiles().length) {
      setTimeout(setFileAsBlob, 500);
      return;
    }
    let file = fileUploadRef.current?.getFiles()[0] as customFile;
    if (imageBlob === file.objectURL) {
      setTimeout(setFileAsBlob, 500);
      return;
    }
    let fileBlob = await compressImage(file, 800, 600, 0.8);
    //setImageBlob(file.objectURL);
    setImageBlob(URL.createObjectURL(fileBlob));
  }
  function uploadFile() {
    fileUploadRef.current?.upload();
  }
  const customBase64Uploader = async (event: any) => {
    setLoading(true);
    // convert file to base64 encoded
    const file = event.files[0];
    if (file === undefined && !imageBlob.length) {
      setLoading(false);
      toastContext.showToast({
        content: 'Kein Bild ausgewählt',
        sevety: 'error',
      });

      return;
    }
    if (params.projectId === 'new' && !selectedCompany) {
      setLoading(false);
      toastContext.showToast({ content: 'Keine Firma ausgewählt', sevety: 'error' });
      return;
    }
    if (!project?.name.length) {
      setLoading(false);
      toastContext.showToast({
        content: 'Unternehmensname fehlt',
        sevety: 'error',
      });
      return;
    }
    let fileBlob;
    if (file) {
      const encryptedFile = await encryptFile(file);
      //const fileBlob = new Blob([encryptedFile], { type: file.type });
      fileBlob = await compressImage(file, 800, 600, 0.8);
      // fileBlob = await fetch(file.objectURL).then((r) => r.blob()); //blob:url
    } else {
      fileBlob = undefined;
    }
    try {
      await UpdateProject({
        projectName: project.name,
        file: fileBlob,
        companyId: selectedCompany ? selectedCompany.id : undefined,
        id: params.projectId && params.projectId !== 'new' ? parseInt(params.projectId) : undefined,
      });
    } catch (error: any) {
      console.log(error.message);
      //CustomToastRef.current?.showToast({content: error,sevety: 'error'})
    }
    //  const reader = new FileReader();

    //  reader.readAsDataURL(blob);

    // reader.onloadend = function () {
    //   const base64data = reader.result;
    // };

    toastContext.showToast({
      content: 'Projekt wurde gespeichert',
      sevety: 'success',
    });
    setLoading(false);
    getProjects().then(_projects => {
      setProjects(_projects);
      if (params.projectId === 'new') {
        let newProjectId = Math.max(..._projects.map((project: Project) => project.id));
        navigation('/edit-groups/' + newProjectId + '');
      }
    });
  };
  return (
    <>
      <Messages ref={msgs} />
      <div className="top-bar DetailsShaddow">
        <div className="LogoContainer">
          <Logo
            asyncLogo={imageBlob.length ? false : true}
            LogoURL={imageBlob.length ? imageBlob : undefined}
          ></Logo>
          {editMode ? (
            <>
              <Icon className={'CompanyeditICON'} style={{ top: '10%' }} icon={'mdi:pencil'}></Icon>
              <div className="uploadbutton">
                <FileUpload
                  onSelect={select => {
                    console.log('SELECTED IMAGE');
                    setFileAsBlob();
                  }}
                  ref={fileUploadRef}
                  style={{ width: '100%', height: '100%' }}
                  mode="advanced"
                  name="image"
                  onUpload={event => {
                    fileUploadRef.current?.clear();
                  }}
                  accept="image/*"
                  customUpload
                  chooseLabel={'Neues Bild'}
                  uploadHandler={customBase64Uploader}
                />
              </div>
            </>
          ) : null}
        </div>
        <div className="LeftOfImg">
          <div className="DetailsTitle">
            {!editMode ? (
              <span className="h2Text primaryFontColor">{project?.name}</span>
            ) : (
              <div style={{ position: 'relative' }}>
                <Icon className={'CompanyeditICON'} icon={'mdi:pencil'}></Icon>
                <InputText
                  value={project?.name}
                  name="projectName"
                  onChange={e => setProject({ ...project, name: e.target.value })}
                />
              </div>
            )}
          </div>
          <div className="SubTitle">
            {editMode && params.projectId === 'new' ? (
              <div>
                Firma:
                <Dropdown
                  value={selectedCompany}
                  onChange={e => {
                    setSelectedCompany(e.value);
                  }}
                  options={companys ?? []}
                  optionLabel="name"
                  placeholder="Firma Auswählen"
                  className="w-full md:w-14rem"
                />
              </div>
            ) : (
              <span>{project?.Company.name}</span>
            )}
          </div>
        </div>
        <div className="divider"></div>
        <div className="DetailsRight">
          {/* <div className="invited">
            <Icon
              className="primaryFontColor"
              style={{ fontSize: '30px' }}
              icon={'fluent:people-12-regular'}
            ></Icon>
            <span>45</span>
          </div>
          <div className="timeLeft">
            <Icon
              className="primaryFontColor"
              style={{ fontSize: '30px' }}
              icon={'bx:stopwatch'}
            ></Icon>
            <div className="stopwatchRightText">
              <span>34 Days Left</span>
              <span>End: 3rd Dec 2022</span>
            </div>

            //TODO implement project time left
          </div> */}
        </div>
        <div className="DetailsMenu">
          <ProjectSettings
            onEditMode={() => navigation('/project/' + params.projectId + '/edit')}
            onGroupEdit={() => navigation('/edit-groups/' + params.projectId)}
          ></ProjectSettings>
        </div>
        {editMode ? (
          <div
            style={{
              width: '150px',
              position: 'absolute',
              bottom: '10px',
              right: '10px',
            }}
          >
            <DefaultButton
              loading={loading}
              action={() => uploadFile()}
              text="Speichern"
            ></DefaultButton>
          </div>
        ) : null}
      </div>
      <Dialog
        draggable={false}
        header={'Projekt aktivieren'}
        visible={modalVisibility}
        onHide={() => setModalVisibility(false)}
      >
        <ProjectActivation></ProjectActivation>
      </Dialog>
    </>
  );
}
