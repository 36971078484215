import React, { ReactElement, createContext, useEffect, useState } from 'react';
import { getProjects } from '../auth/apiconnect';
import { Company, initialCompanyData } from './companyContext';

export type Project = {
  projectActivated?: boolean;
  id: number;
  name: string;
  imageUrl: string;
  Company: Company;
  rights?: rightKey[];
  joinKey?: string;
  secretKey?: string;
};
export type Payment = {
  id: number;
  from?: Date;
  to?: Date;
  state?: PaymentState;
  stripeId?: string;
  Project?: Project;
  sendInvite?: Date;
};
export enum PaymentState {
  created = 0,
  awaitingPayment = 1,
  successful = 2,
  cancelled = 3,
  failed = 4,
}
export enum rightKey {
  viewProject = 0,
  sendNotification = 1,
  uploadFiles = 2,
  root = -1,
}
type ProjectContextType = {
  projects: Project[] | null;
  setProjects: React.Dispatch<React.SetStateAction<Project[] | null>>;
};

const initialData: Project = {
  id: 0,
  name: '',
  imageUrl: '',
  Company: initialCompanyData,
};
export const ProjectContext = createContext<ProjectContextType>({
  projects: [initialData],
  setProjects: () => { },
});
export function ProjectDataProvider({ children = <></> }): ReactElement {
  const [projects, setProjects] = useState<Project[] | null>(null);
  useEffect(() => {
    // Simulate fetching data
    setTimeout(() => {
      getProjects().then(_projects => {
        console.log('ASYNC LOADED Projects', _projects);
        setProjects(_projects);
      });
    }, 0);
  }, []);

  return (
    <ProjectContext.Provider value={{ projects, setProjects }}>{children}</ProjectContext.Provider>
  );
}
