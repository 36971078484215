import React, { useState, useRef, useEffect } from 'react';
import DefaultButton from '../../../components/button';

interface VideoPlayerProps {
  src: string;
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({ src }) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);

  useEffect(() => {
    const videoElement = videoRef.current;

    const updateTime = () => {
      if (videoElement) {
        setCurrentTime(videoElement.currentTime);
      }
    };

    const updateDuration = () => {
      if (videoElement) {
        setDuration(videoElement.duration);
      }
    };

    if (videoElement) {
      videoElement.addEventListener('timeupdate', updateTime);
      videoElement.addEventListener('loadedmetadata', updateDuration);
    }

    return () => {
      if (videoElement) {
        videoElement.removeEventListener('timeupdate', updateTime);
        videoElement.removeEventListener('loadedmetadata', updateDuration);
      }
    };
  }, []);

  const playPauseHandler = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const stopHandler = () => {
    if (videoRef.current) {
      videoRef.current.pause();
      videoRef.current.currentTime = 0;
      setIsPlaying(false);
    }
  };

  const onSeek = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newTime = parseFloat(event.target.value);
    if (videoRef.current) {
      videoRef.current.currentTime = newTime;
      setCurrentTime(newTime);
    }
  };

  const formatTime = (time: number): string => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  return (
    <div>
      <video style={{ maxWidth: '100%' }} ref={videoRef} src={src} controls />
      <div className="uploadFileButton">
        <DefaultButton
          text={isPlaying ? 'Pause' : 'Play'}
          action={playPauseHandler}
        ></DefaultButton>
        <DefaultButton text="Stop" action={stopHandler}></DefaultButton>
      </div>
      <div>
        <span>Time: {formatTime(currentTime)}</span>
        <span> / </span>
        <span>Duration: {formatTime(duration)}</span>
      </div>
      <input
        type="range"
        min="0"
        max={duration || 0}
        step="1"
        value={currentTime}
        onChange={onSeek}
      />
    </div>
  );
};

export default VideoPlayer;
