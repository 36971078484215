import { useContext, useEffect, useState } from 'react';
import MainComponent from '../../components/page/main-component';
import {
  deleteFile,
  getFile,
  getFileInformation,
  getFiles,
  getProjects,
} from '../../func/auth/apiconnect';
import { useNavigate, useParams } from 'react-router-dom';
import { decryptFile } from '../../components/generic/encrypt';
import './view-file.css';
import {
  blobToFile,
  fileTypes,
  isAudio,
  isImage,
  isVideo,
  uint8ArrayToBlob,
  urlToBlob,
} from '../../components/generic/compress';
import { SimpleFile } from '../project/project-details';
import { AudioPlayer } from './fileViewers/audioPlayer';
import DefaultButton from '../../components/button';
import DownloadButton from './downloadButton';
import { ProgressSpinner } from 'primereact/progressspinner';
import VideoPlayer from './fileViewers/videoPlayer';
import PdfViewer from './fileViewers/pdfViewer';
import { Toolbar } from 'primereact/toolbar';
import React from 'react';
import { Button } from 'primereact/button';
import { Project, ProjectContext, rightKey } from '../../func/context/projectContext';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import ToastContext, { toastContext } from '../../func/context/toastContext';

export function ViewFile() {
  const params = useParams();
  const navigate = useNavigate();
  const [simpleFile, setFile] = useState<SimpleFile>();
  const [fileUrl, setFileUrl] = useState('');
  const [fileBlob, setFileBlob] = useState<Blob>();
  const [loading, setLoading] = useState(true);
  const { projects, setProjects } = useContext(ProjectContext);
  const toastContext = useContext(ToastContext);
  const [project, setProject] = useState<Project>();
  const confirm1 = () => {
    confirmDialog({
      message: 'Die Datei wird unwiderruflich gelöscht.',
      header: 'Bestätigen',
      acceptLabel: 'Ja',
      rejectLabel: 'Nein',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        deleteFile(parseInt(params.fileId ?? '0')).then(() => {
          navigate(`/project/${project?.id}`);
          toastContext.showToast({
            content: 'Datei gelöscht',
            sevety: 'success',
          });
        });
      },
      reject: () => { },
    });
  };
  async function downloadFile() {
    let file = await getFile(parseInt(params.fileId ?? '0'));
    let decryptedFile = await decryptFile(file, project?.secretKey);
    let fileBlob = uint8ArrayToBlob(decryptedFile, file.fileType);
    setFileUrl(URL.createObjectURL(fileBlob));
    setFileBlob(fileBlob);
    setLoading(false);
  }
  async function getProject() {
    let _projects = await getProjects();
    const fileInformation = await getFileInformation(parseInt(params.fileId ?? '0'));
    setFile(fileInformation);
    setProject(_projects.find((project: Project) => project.id === fileInformation?.Project.id))

  }

  useEffect(() => {
    if (!project) return;
    if (!fileBlob) {
      downloadFile();
      return;
    }
  }, [project])
  useEffect(() => {
    getProject();
  }, []);
  if (loading) {
    return (
      <MainComponent
        pageContent={
          <div
            style={{
              width: '100%',
              height: '90vh',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <ProgressSpinner />
          </div>
        }
      ></MainComponent>
    );
  }
  const startContent = (
    <React.Fragment>
      <div className="fileViewerButtonContainer">
        <DownloadButton fileType={simpleFile?.fileExtension ?? ""} blob={fileBlob} fileName={simpleFile?.fileName ?? ''}></DownloadButton>
        {project?.rights?.includes(rightKey.uploadFiles) && (
          <>
            <DefaultButton icon="material-symbols:edit" action={() => navigate("/upload-files/" + project.id + '/' + params.fileId)} text="Bearbeiten"></DefaultButton>
            <DefaultButton icon="mdi:trash" classes="deletedButton" action={confirm1} text="Löschen"></DefaultButton>
          </>
        )}
      </div>
      <i className="pi pi-bars p-toolbar-separator mr-2" />
    </React.Fragment>
  );
  return (
    <MainComponent
      pageContent={
        <div style={{ position: 'relative', paddingBottom: '130px' }}>
          {isImage(simpleFile?.fileExtension ?? '') ? (
            <img src={fileUrl}></img>
          ) : isAudio(simpleFile?.fileExtension ?? '') ? (
            <AudioPlayer audioFileName={simpleFile?.fileName} src={fileUrl}></AudioPlayer>
          ) : isVideo(simpleFile?.fileExtension ?? '') ? (
            <VideoPlayer src={fileUrl}></VideoPlayer>
          ) : simpleFile?.fileExtension === fileTypes.PDF ? (
            <PdfViewer src={fileUrl}></PdfViewer>
          ) : <p style={{}}>Diese Datei können wir aktuell nicht darstellen. Du kannst Sie dir aber bequem herunterladen</p>}
          <div
            style={{
              position: 'absolute',
              display: 'flex',
              zIndex: 5,
              justifyContent: 'center',
              width: '100%',
              bottom: '5px',
            }}
          >
            <Toolbar style={{ padding: '5px' }} start={startContent} />
          </div>
          <ConfirmDialog />
        </div>
      }
    ></MainComponent>
  );
}
