export enum PAGES {
  DASHBOARD = '/',
  PROJECTS = '/projects',
  EDITPROJECT = '/project/:projectId/edit',
  COMPANYS = '/companys',
  COMPANYOVERVIEW = '/company/:companyId',
  EDITCompany = '/company/:companyId/edit',
  EDITProjectGroups = '/edit-groups/:projectId',
  UPLOADFILES = '/upload-files/:projectId/:fileId',
  ViewFiles = '/view-file/:fileId',
  ShareProject = '/share-project/:projectId',
}
export function IfIsPage(PAGE: PAGES) {
  if (window.location.pathname === PAGE) {
    return true;
  }
  return false;
}
