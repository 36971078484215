import { Icon } from '@iconify/react';
import './button.css';
import { Link } from 'react-router-dom';
export default function DefaultButton({
  url = '',
  maxWidth = 'unset',
  outlined = false,
  text = '',
  icon = 'uil:plus',
  action = () => {},
  disabled = false,
  classes = '',
  loading = false,
}) {
  let classes2 = 'customButton buttonShaddowv' + (disabled ? ' disabledHREF ' : ' ') + classes;
  if (url.length && !loading)
    return (
      <Link to={url} style={{ maxWidth: maxWidth }} className={classes2}>
        <Icon icon={!loading ? icon : 'line-md:loading-loop'}></Icon>
        <span>{text}</span>
      </Link>
    );
  return (
    <a
      href="#"
      
      onClick={e => {
        if (loading) return;
        e.preventDefault();
        action();
      }}
      style={{ maxWidth: maxWidth }}
      className={classes2}
    >
      <Icon icon={!loading ? icon : 'line-md:loading-loop'}></Icon>
      <span>{text}</span>
    </a>
  );
}
