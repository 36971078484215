import EventEmitter from 'events';
import { useAuth } from 'react-oidc-context';
import { RequireAuth } from '../../App';
import { useContext, useRef } from 'react';
import { User } from '../../components/profile/profile';
import { Company } from '../context/companyContext';
var token: string;
export function setToken(access_token: string | undefined) {
  if (!access_token) return;
  token = access_token;
}
export function getToken() {
  return token;
}
enum METHOD {
  GET = 'GET',
  POST = 'POST',
  DELETE = 'DELETE',
}
enum RESPONSETYPE {
  JSON = 'JSON',
  BLOB = 'BLOB',
}
interface apiconnect {
  url: string;
  method: METHOD;
  data?: any | FormData;
  responseType?: RESPONSETYPE;
}
export async function Apiconnect(apiData: apiconnect) {
  const url = 'https://dev.api.mach.app/v2' + apiData.url;
  const requestOptions: RequestInit = {
    method: apiData.method,
  };
  if (apiData.data instanceof FormData) {
    requestOptions.body = apiData.data;
    requestOptions.headers = {
      Authorization: `Bearer ${token}`,
    };
  } else {
    requestOptions.headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };
    requestOptions.body = JSON.stringify(apiData.data);
  }
  try {
    const response = await fetch(url, requestOptions);
    if (response.status === 200) {
      if (!apiData.responseType || apiData.responseType === RESPONSETYPE.JSON) {
        return response.json();
      } else if (apiData.responseType === RESPONSETYPE.BLOB) {
        return response.blob();
      } else return response.text();
    } else if (response.status === 400) {
      try {
        let error = await response.json();
        throw new Error(error.error);
      } catch (error) {
        throw new Error('Unknown Error');
      }
    } else if (response.status === 401) {
      window.location.reload();
    } else if (response.status === 404) {
      console.log('NOT FOUND');
    } else {
      throw new Error(
        `Request failed with status: ${response.status} and message: ${await response.text()}`,
      );
    }
  } catch (error) {
    console.error(error);
    throw new Error('An error occurred while sending the data');
  }
}

export async function UpdateCompany(companyData: Company, file: Blob | undefined) {
  const _data: FormData = new FormData();
  if (file) {
    _data.append('file', file);
  }
  if (companyData.InvoiceAddress === undefined) {
    companyData.InvoiceAddress = {};
  }

  for (let key of Object.keys(companyData.InvoiceAddress!)) {
    _data.append(`invoiceAddress[${key}]`, (companyData.InvoiceAddress as any)[key]);
  }

  _data.append('name', companyData.name ?? '');
  _data.append('id', companyData?.id?.toString() ?? '');
  return await Apiconnect({
    data: _data,
    method: METHOD.POST,
    url: '/company' + (companyData.id ? '/' + companyData.id : ''),
  });
}
export async function getCompanys() {
  return await Apiconnect({ method: METHOD.GET, url: '/company' });
}
export async function getProjectImage(projectImage: string):Promise<Blob | false>{
  if(projectImage === null) return false;
  return await Apiconnect({
    method: METHOD.GET,
    url: '/project/image/' + projectImage,
    responseType: RESPONSETYPE.BLOB,
  });
}
export async function getCompanyImage(companyImage: string):Promise<Blob | false>{
  if (companyImage === null) { return false; }
  return await Apiconnect({
    method: METHOD.GET,
    url: '/company/image/' + companyImage,
    responseType: RESPONSETYPE.BLOB,
  });
}
export async function removeCompany(companyId: string | undefined) {
  if (!companyId) alert('MISSING COMPANY ID');
  return await Apiconnect({
    method: METHOD.DELETE,
    url: '/company/' + companyId,
  });
}
export async function removeEmployee(userid: number, companyId: number) {
  return await Apiconnect({
    method: METHOD.DELETE,
    url: '/employee/' + companyId,
    data: { userId: userid },
  });
}
export async function addEmployee(employeeEmail: string, companyId: string | undefined) {
  return await Apiconnect({
    method: METHOD.POST,
    url: '/employee',
    data: { email: employeeEmail, companyId: companyId },
  });
}
export async function getEmployees(companyId: number) {
  return await Apiconnect({
    method: METHOD.GET,
    url: '/employees/' + companyId,
  });
}

export async function getProjects() {
  return await Apiconnect({
    method: METHOD.GET,
    url: '/projects',
  });
}
interface UpdateProject {
  id: number | undefined;
  projectName: string;
  file?: Blob;
  companyId?: number;
}
export async function UpdateProject(updateProject: UpdateProject) {
  const _data: FormData = new FormData();
  _data.append('name', updateProject.projectName);
  if (updateProject.companyId) {
    _data.append('companyId', updateProject.companyId.toString());
  }
  if (updateProject.file) {
    _data.append('file', updateProject.file);
  }
  console.log('SENDING ', '/project' + (updateProject.id ? '/' + updateProject.id : ''));
  return await Apiconnect({
    method: METHOD.POST,
    url: '/project' + (updateProject.id ? '/' + updateProject.id : ''),
    data: _data,
  });
}
export async function getGroups(projectId: number) {
  return await Apiconnect({
    method: METHOD.GET,
    url: '/groups/' + projectId,
  });
}
export async function addGroup(groupName: string, projectId: number) {
  return await Apiconnect({
    method: METHOD.POST,
    url: '/groups/' + projectId,
    data: { name: groupName },
  });
}
export async function getProjectRoles(projectId: number) {
  return await Apiconnect({
    method: METHOD.GET,
    url: '/project/' + projectId + '/roles',
  });
}

export async function addGroupMember(
  userEmails: string[],
  roleId: number,
  groupIds: number[],
  projectId: number,
) {
  return await Apiconnect({
    method: METHOD.POST,
    url: '/group/member',
    data: { userEmails: userEmails, roleId: roleId, groupIds: groupIds, projectId: projectId },
  });
}
export async function getUsers(projectId: number) {
  return await Apiconnect({
    method: METHOD.GET,
    url: '/group/' + projectId + '/members',
  });
}

export async function deleteUsers(userIds: number[], projectId: number) {
  return await Apiconnect({
    method: METHOD.DELETE,
    url: '/group/member',
    data: { userIds: userIds, projectId: projectId },
  });
}
interface UploadFile {
  projectId: number;
  fileName: string;
  groupIds: number[];
  type: string;
  parent: number;
  confirmationRequired: boolean;
  file: Blob | undefined;
  id?: number;
}
export enum FileUploadTypes {
  Folder,
  File,
}
export async function postFile(params: UploadFile) {
  const _data: FormData = new FormData();
  if (params.file) {
    _data.append('file', params.file);
  }
  _data.append('projectId', params.projectId.toString());
  _data.append('fileName', params.fileName);
  _data.append('groupIds', params.groupIds.toString());
  _data.append('type', params.type);
  _data.append('parent', params.parent.toString());
  _data.append('confirmationRequired', params.confirmationRequired.toString());

  return await Apiconnect({
    method: METHOD.POST,
    url: '/upload' + (params.id ? '/' + params.id : ''),
    data: _data,
  });
}
export async function getFiles(projectId: number) {
  return await Apiconnect({
    method: METHOD.GET,
    url: '/uploads/' + projectId,
  });
}
export async function getFile(fileId: number) {
  return await Apiconnect({
    method: METHOD.GET,
    responseType: RESPONSETYPE.BLOB,
    url: '/upload/' + fileId,
  });
}
export async function getFileInformation(fileId: number) {
  return await Apiconnect({
    method: METHOD.GET,
    url: '/upload-info/' + fileId,
  });
}
export async function deleteFile(fileId: number) {
  return await Apiconnect({
    method: METHOD.DELETE,
    url: '/file/' + fileId,
  });
}
export async function downloadFolders(projectId: number) {
  return await Apiconnect({
    method: METHOD.GET,
    url: '/folders/' + projectId,
  });
}
export interface ApiFile {
  confirmationRequired: boolean;
  fileExtension: string;
  fileName: string;
  filePath: string;
  fileType: number;
  parent: number;
  projectId: number;
  id: number;
  Updated: string;
  Created: string;
}

export async function startPayment(
  projectId: number,
  startDate: number,
  endDate: number,
  sendInvite: number,
) {
  return await Apiconnect({
    method: METHOD.POST,
    url: '/payment',
    data: { projectId, startDate, endDate, sendInvite },
  });
}
export async function getActivations(projectId: number) {
  return await Apiconnect({
    method: METHOD.GET,
    url: '/payments/' + projectId,
  });
}
export async function getActivationsByProjectId(projectId: number) {
  return await Apiconnect({
    method: METHOD.GET,
    url: '/payment/' + projectId,
  });
}
export async function downloadInvoice(activationId: number) {
  return await Apiconnect({
    method: METHOD.GET,
    url: '/download-invoice/' + activationId,
    responseType: RESPONSETYPE.BLOB,
  });
}
export async function changeFolderName(folderId: number, folderName: string) {
  return await Apiconnect({
    method: METHOD.POST,
    url: '/folder/' + folderId,
    data: { folderName },
  });
}
export async function sendMessage(projectId: number, message: string, groupIds: number[]) {
  return await Apiconnect({
    method: METHOD.POST,
    url: '/chat',
    data: { projectId, message, groupIds },
  });
}
export async function getMessages(projectId: number) {
  return await Apiconnect({
    method: METHOD.GET,
    url: '/chat/' + projectId,
  });
}

export async function getUser() {
  return await Apiconnect({
    method: METHOD.GET,
    url: '/user',
  });
}
export async function saveUser(user: User) {
  return await Apiconnect({
    method: METHOD.POST,
    url: '/user',
    data: user,
  });
}
export async function getNotifications() {
  return await Apiconnect({
    method: METHOD.GET,
    url: '/notification',
  });
}
export async function joinGroup(projectId: number) {
  return await Apiconnect({
    method: METHOD.POST,
    url: '/group/invitation/' + projectId,
  });
}
export async function deleteProject(projectId: number) {
  return await Apiconnect({
    method: METHOD.DELETE,
    url: '/project/' + projectId,
  });
}
export async function leaveProject(projectId: number) {
  return await Apiconnect({
    method: METHOD.DELETE,
    url: '/project/leave/' + projectId,
  });
}
export async function markAsRead(notificationId: number) {
  return await Apiconnect({
    method: METHOD.POST,
    url: '/notification/' + notificationId,
  });
}
