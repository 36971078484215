import { useEffect, useState } from 'react';
import Logo from '../generic/logo';
import { getProjectImage } from '../../func/auth/apiconnect';
import "./project-logo.css";
export function ProjectLogo({ logoId = '', HeightPx = 0, borderLeft=true }) {
  const [logo, setLogo] = useState('');
  useEffect(() => {
    if(logoId===null || logoId.length === 0) return;
    getProjectImage(logoId).then(image => {
      if(!image) return;
      setLogo(URL.createObjectURL(image));
    });
  }, [logoId]);

  return <Logo classNames={borderLeft===false?'removeBorderLeft':''} HeightInPx={HeightPx} asyncLogo={logo.length ? false : true} LogoURL={logo}></Logo>;
}
