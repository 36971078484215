import { Dialog } from 'primereact/dialog';
import Button from '../button';
import { useEffect, useState } from 'react';
import { getUser, saveUser } from '../../func/auth/apiconnect';
import { InputText } from 'primereact/inputtext';
import './profile.css';
import DefaultButton from '../button';
import { InputSwitch } from 'primereact/inputswitch';
import { Calendar } from 'primereact/calendar';
interface ProfileInterface {
  visible: boolean;
  onVisibleChange: (change: boolean) => void;
}
export interface User {
  registered?: Date;
  id?: number;
  Updated?: Date;
  Created?: Date;
  keycloakId?: string;
  email?: string;
  username?: string;
  firstname?: string;
  lastName?: string;
  birthDate?: Date;
  houseNumber?: string;
  zip?: string;
  city?: string;
  public?: boolean;
  phoneNumber?: string;
  street?: string;
}
export default function Profile(profileParams: ProfileInterface) {
  const [globalUser, setGlobalUser] = useState<User>();
  async function downloadUser() {
    let user = await getUser();
    setGlobalUser(user);
  }
  async function saveProfile() {
    if (!globalUser) return;
    await saveUser(globalUser);
  }
  useEffect(() => {
    downloadUser();
  }, []);
  useEffect(() => {
    console.log(globalUser);
  }, [globalUser]);
  return (
    <div className="card flex justify-content-center">
      <Dialog
        header="Profil bearbeiten"
        visible={profileParams.visible}
        style={{ width: '50vw' }}
        onHide={() => profileParams.onVisibleChange(false)}
      >
        <div style={{ paddingTop: '30px' }}>
          <p style={{ paddingLeft: '10px' }}>Kontakt</p>
          <div className={'profileNameInput'}>
            <span className="p-float-label">
              <InputText
                id="Vorname"
                value={globalUser?.firstname}
                onChange={e => setGlobalUser({ ...globalUser, firstname: e.target.value })}
              />
              <label htmlFor="Vorname">Vorname</label>
            </span>
            <span className="p-float-label">
              <InputText
                id="Nachname"
                value={globalUser?.lastName}
                onChange={e => setGlobalUser({ ...globalUser, lastName: e.target.value })}
              />
              <label htmlFor="Nachname">Nachname</label>
            </span>
          </div>
          <div className={'profileNameInput'}>
            <span className="p-float-label">
              <InputText
                id="Telefonnummer"
                value={globalUser?.phoneNumber}
                onChange={e => setGlobalUser({ ...globalUser, phoneNumber: e.target.value })}
              />
              <label htmlFor="Telefonnummer">Telefonnummer</label>
            </span>
            <span className="p-float-label">
              <Calendar
                inputId="birth_date"
                value={new Date(globalUser?.birthDate??0)}
                onChange={e => setGlobalUser({ ...globalUser, birthDate: e.value??new Date() })}
              />
              <label htmlFor="birth_date">Geburtsdatum</label>
            </span>
          </div>
          <p style={{ paddingLeft: '10px' }}>Adresse</p>
          <div className={'profileNameInput'}>
            <span className="p-float-label">
              <InputText
                id="Straße"
                value={globalUser?.street}
                onChange={e => setGlobalUser({ ...globalUser, street: e.target.value })}
              />
              <label htmlFor="Straße">Straße</label>
            </span>
            <span className="p-float-label">
              <InputText
                id="Number"
                value={globalUser?.houseNumber}
                onChange={e => setGlobalUser({ ...globalUser, houseNumber: e.target.value })}
              />
              <label htmlFor="Number">Hausnummer</label>
            </span>
          </div>
          <div className={'profileNameInput'}>
            <span className="p-float-label">
              <InputText
                id="Postleitzahl"
                value={globalUser?.zip}
                onChange={e => setGlobalUser({ ...globalUser, zip: e.target.value })}
              />
              <label htmlFor="Postleitzahl">Postleitzahl</label>
            </span>
            <span className="p-float-label">
              <InputText
                id="Ort"
                value={globalUser?.city}
                onChange={e => setGlobalUser({ ...globalUser, city: e.target.value })}
              />
              <label htmlFor="Ort">Ort</label>
            </span>
          </div>
          <span className="publicProfileSwitch">
            <div>Produktionen denen ich beigetreten bin können diese Informationen sehen</div>
            <InputSwitch
              checked={globalUser?.public ?? false}
              onChange={e => setGlobalUser({ ...globalUser, public: e.value })}
            />
          </span>

          <DefaultButton
            text="Speichern"
            action={() => {
              saveProfile();
            }}
          ></DefaultButton>
        </div>
      </Dialog>
    </div>
  );
}
