import { Icon } from '@iconify/react';
import Logo from '../generic/logo';
import './company.css';
import ProjectSettings from '../settings/project';
import { PAGES } from '../../enum/pages';
import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { CompanyContext } from '../../func/context/companyContext';
import Spacer from '../generic/spacer';
import { getCompanyImage } from '../../func/auth/apiconnect';
import { CompanyLogo } from './comany-logo';
import { handleScrollToTop } from '../generic/scroll';
export default function CompanyOverview() {
  type companyLogo = {
    logoUrl: string;
  };
  const [editMode, setEditMode] = useState(false);
  const { companys, setCompanys } = useContext(CompanyContext);
  if (companys)
    return (
      <>
        {companys.map(company => (
          <>
            <Spacer key={company.id} space={20}></Spacer>
            <Link
              key={company.id}
              className="nolink"
              to={'/company/' + company.id}
              onClick={() => handleScrollToTop()}
            >
              <div className="CompanyDetailsShaddow company" style={{ height: '90px' }}>
                <div className="companyLeft">
                  <div className="CompanyLogo">
                    <CompanyLogo HeightPx={90} logoId={company.logoUrl}></CompanyLogo>
                  </div>
                  <div className="CompanyName">
                    <span>{company.name}</span>
                  </div>
                </div>
                <div className="companyRight">
                 {/*  <div className="projectCount">
                    <Icon icon={'charm:clipboard-tick'}></Icon>
                    <span>12</span>
                  </div>
                  <ProjectSettings></ProjectSettings> */}
                </div>
              </div>
            </Link>
          </>
        ))}
      </>
    );
  return <div className="CompanyDetailsShaddow company gradient" style={{ height: '96px' }}></div>;
}
