import React, { useContext, useEffect, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import { QRCode } from 'react-qrcode-logo';
import './project-share.css';
import DefaultButton from '../../components/button';
import { useNavigate, useParams } from 'react-router-dom';
import { Project, ProjectContext } from '../../func/context/projectContext';
import { getCompanyImage } from '../../func/auth/apiconnect';
import { blobToFile } from '../../components/generic/compress';
import { CompanyLogo } from '../../components/company/comany-logo';
import { ProjectLogo } from '../../components/project/project-logo';
import { ProgressSpinner } from 'primereact/progressspinner';
const PrintComponent = React.forwardRef((props, ref) => {
  const params = useParams();
  const { projects } = useContext(ProjectContext);
  const [project, setProject] = useState<Project>();
  useEffect(() => {
    let project = projects?.find(project => project.id === parseInt(params.projectId ?? '0'));
    console.log(project);
    setProject(project);
  }, [projects]);
  return (
    <div ref={ref as any}>
      <div className="projectShareHeader">
        <h3>Kein Ablauf?</h3>
        <h3>Keine Dispo?</h3>
        <h3>Kein Plan?</h3>
      </div>
      <div className="projectShareContent">
        <h3>Finde hier</h3>
        <h3>alle Infos</h3>
        <h3>Zum Projekt</h3>
        <h3>"{project?.name}"</h3>
      </div>
      <div
        style={{ position: 'fixed', bottom: '100px', left: '50%', transform: 'translate(-50%,0)' }}
      >
        <QRCode
          size={300}
          logoImage={'/MACH_LOGO_A2-2.png'}
          removeQrCodeBehindLogo={true}
          value={'/?joinProject=' + project?.joinKey}
        ></QRCode>
      </div>
    </div>
  );
});

const PrintButton = () => {
  const navigation = useNavigate();
  const componentRef = useRef(null);
  const params = useParams();
  const { projects } = useContext(ProjectContext);
  const [project, setProject] = useState<Project>();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    let project = projects?.find(project => project.id === parseInt(params.projectId ?? '0'));
    console.log(project);
    setProject(project);
    if (project?.id) {
      setLoading(false);
    }
  }, [projects]);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current as any,
  });
  if (loading) {
    return (
      <>
        <ProgressSpinner />
      </>
    );
  }
  return (
    <div>
      <div style={{ maxWidth: '150px', marginLeft: '50px' }}>
        <DefaultButton text="Ducken" action={handlePrint}></DefaultButton>
        <DefaultButton
          text="TestQRCode"
          action={() => navigation('/?joinProject=' + project?.joinKey)}
        ></DefaultButton>
      </div>
      <PrintComponent ref={componentRef} />
    </div>
  );
};

const App = () => {
  return (
    <div>
      <PrintButton />
    </div>
  );
};

export default App;
