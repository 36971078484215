import { createContext, createRef, useRef } from 'react';
import { ToastContent, CustomToast, CustomToastRef } from '../../components/generic/toast';
import { Toast } from 'primereact/toast';

export type ToastContextType1 = {};

class ToastContextType {
  toastRef: React.RefObject<CustomToastRef> | undefined;
  showToast(toastContent: ToastContent) {
    this.toastRef?.current?.showToast(toastContent);
  }
}

export const toastContext = new ToastContextType();

const ToastContext = createContext<ToastContextType>(toastContext);

export default ToastContext;
