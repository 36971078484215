export default function verifyEmail(email: string) {
  if (
    email
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-ZäüöÄÜÖ\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      )
  ) {
    return true;
  }
  return false;
}
