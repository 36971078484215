import { EventEmitter } from 'events';
import { useEffect, useRef } from 'react';

const eventBus = new EventEmitter();

export default eventBus;

interface Company {
  companyName: string;
  id: number;
}
interface Project {
  projectName: string;
  id: number;
}
export function Data() {
  let token: string;
  let companys: Company[];
  let projects: Project[];

  useEffect(() => {
    const handleEvent = () => {
      // Handle the event
      alert('Event received in ComponentA');
    };
    const getCompanys = (_companys: Company[]) => {
      companys = _companys;
    };
    const getProjects = (_projects: Project[]) => {
      projects = _projects;
    };
    const getToken = () => {
      return token;
    };

    // Subscribe to the event
    eventBus.on('getCompanys', getCompanys);
    eventBus.on('getProjects', getProjects);

    eventBus.on('getToken', getToken);
    // Unsubscribe when the component unmounts
    return () => {
      eventBus.off('getCompany', handleEvent);
      eventBus.off('getProjects', handleEvent);
    };
  }, []);
}
