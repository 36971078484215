import { Link } from 'react-router-dom';
import Spacer from '../generic/spacer';
import { ProjectLogo } from './project-logo';
import { Project, rightKey } from '../../func/context/projectContext';
interface projectFoldedInterface {
  project: Project;
}
export default function ProjectFolded(projectFoldedParams: projectFoldedInterface) {
  return (
    <>
      <Spacer key={projectFoldedParams.project.id} space={20}></Spacer>
      <Link
        to={'/project/' + projectFoldedParams.project.id}
        className={
          projectFoldedParams.project.projectActivated
            ? 'link activeProject'
            : 'link inactiveProject'
        }
      >
        <div className="ProjectTab card">
          <div className="left">
            <ProjectLogo HeightPx={90} logoId={projectFoldedParams.project.imageUrl}></ProjectLogo>
            <div className="center">
              <div className="title">
                <span>{projectFoldedParams.project.name}</span>
              </div>
              <div className="subtitle">
                <span>{projectFoldedParams.project.Company.name}</span>
              </div>
            </div>
          </div>
          <div className="right">
            {/* 
            <div className="centerProjectText">
              <Icon className="primaryFontColor" icon={'fluent:people-12-regular'}></Icon>
              <span>45</span>
            </div>
            <div className="centerProjectText">
              <Icon className="primaryFontColor" icon={'bx:stopwatch'}></Icon>
              <span>12 Tage verbleibend</span>
            </div>
            <div className="centerProjectText primaryFontColor">
              <NotificationBell></NotificationBell>
            </div>
            <div className="centerProjectText">
              <Icon icon={'octicon:kebab-horizontal-16'}></Icon>
              //TODO Implement Project Settings in overview
            </div> */}
          </div>
        </div>
      </Link>
    </>
  );
}
