import { Icon } from '@iconify/react';
import Logo from '../generic/logo';
import './company-expand.css';
import CompanySettings from '../settings/company';
import { useContext, useEffect, useRef, useState } from 'react';
import DefaultButton from '../button';
import { InputText } from 'primereact/inputtext';
import { FileUpload } from 'primereact/fileupload';
import './company-expand.css';
import { useNavigate, useParams } from 'react-router-dom';
import { encryptFile } from '../generic/encrypt';
import { useAuth } from 'react-oidc-context';
import { UpdateCompany, getCompanyImage, getCompanys } from '../../func/auth/apiconnect';
import { CustomToast, CustomToastRef } from '../generic/toast';
import { Company, CompanyContext } from '../../func/context/companyContext';
import { compressImage } from '../generic/compress';
import { customFile } from '../../pages/files/upload-file';
import ToastContext from '../../func/context/toastContext';
import verifyEmail from '../generic/verifyEmail';

export default function CompanyExpand({ editMode = false }) {
  const { companys, setCompanys } = useContext(CompanyContext);
  const params = useParams();
  let auth = useAuth();
  const navigation = useNavigate();

  const toastContext = useContext(ToastContext);
  const fileUploadRef = useRef<FileUpload>(null);
  const [loading, setLoading] = useState(false);
  const [imageBlob, setImageBlob] = useState('');
  const [companyData, setCompanyData] = useState<Company>({ logoUrl: '', name: '' });
  function uploadFile() {
    fileUploadRef.current?.upload();
  }
  async function setFileAsBlob() {
    if (!fileUploadRef.current?.getFiles().length) {
      setTimeout(setFileAsBlob, 500);
      return;
    }
    let file = fileUploadRef.current?.getFiles()[0] as customFile;
    if (imageBlob === file.objectURL) {
      setTimeout(setFileAsBlob, 500);
      return;
    }
    let fileBlob = await compressImage(file, 800, 600, 0.8);
    //setImageBlob(file.objectURL);
    setImageBlob(URL.createObjectURL(fileBlob));
  }
  useEffect(() => {
    if (!params.companyId) {
      return;
    }
    const urlCompanyId: number = parseInt(params.companyId);
    if (companys === null) return;
    const company = companys.find(singleCompany => singleCompany.id === urlCompanyId);
    if (!company) return;
    setCompanyData(company);
    getCompanyImage(company.logoUrl ?? '').then(image => {
      if(!image) return;
      setImageBlob(URL.createObjectURL(image));
    });
  }, [companys]);
  const customBase64Uploader = async (event: any) => {
    setLoading(true);
    // convert file to base64 encoded
    const file = event.files[0];
    if (file === undefined && !imageBlob.length) {
      setLoading(false);
      toastContext.showToast({
        content: 'Kein Bild ausgewählt',
        sevety: 'error',
      });
      return;
    }
    if (!companyData?.name?.length) {
      setLoading(false);
      toastContext.showToast({
        content: 'Unternehmensname fehlt',
        sevety: 'error',
      });
      return;
    }
    if (
      !companyData.InvoiceAddress?.city?.length ||
      !companyData.InvoiceAddress.companyName?.length ||
      !companyData.InvoiceAddress.country?.length ||
      !companyData.InvoiceAddress.email?.length ||
      !companyData.InvoiceAddress.houseNumber?.length ||
      !companyData.InvoiceAddress.postcode?.length ||
      !companyData.InvoiceAddress.street?.length ||
      !companyData.InvoiceAddress.taxNumber?.length
    ) {
      setLoading(false);
      toastContext.showToast({ content: 'Rechnungsdaten fehlen', sevety: 'error' });
      return;
    }
    if (!verifyEmail(companyData.InvoiceAddress.email)) {
      setLoading(false);
      toastContext.showToast({ content: 'E-Mail ist nicht korrekt', sevety: 'error' });
      return;
    }
    let fileBlob;
    if (file) {
      const encryptedFile = await encryptFile(file);
      //const fileBlob = new Blob([encryptedFile], { type: file.type });
      fileBlob = await compressImage(file, 800, 600, 0.8);
      // fileBlob = await fetch(file.objectURL).then((r) => r.blob()); //blob:url
    } else {
      fileBlob = undefined;
    }
    try {
      const company = await UpdateCompany(
        {
          name: companyData.name,
          InvoiceAddress: companyData.InvoiceAddress,
          id: params.companyId ? parseInt(params.companyId) : undefined,
        },
        fileBlob,
      );
      toastContext.showToast({
        content: 'Firma wurde gespeichert',
        sevety: 'success',
      });
    } catch (error: any) {
      console.log(error.message);
      toastContext.showToast({ content: 'Fehler', sevety: 'error' });
    }
    //  const reader = new FileReader();

    //  reader.readAsDataURL(blob);

    // reader.onloadend = function () {
    //   const base64data = reader.result;
    // };

    setLoading(false);
    getCompanys().then(_company => {
      setCompanys(_company);
      if (!params.companId) {
        let newCompanyId = Math.max(..._company.map((comp: any) => comp.id));
        navigation('/company/' + newCompanyId + '/edit');
      }
    });
  };
  return (
    <>
      <div className="DetailsShaddow companyTopBar" style={{ position: 'relative' }}>
        <div className="CompanyImgContainer">
          <div className="LogoContainer">
            <Logo
              asyncLogo={imageBlob.length ? false : true}
              LogoURL={imageBlob.length ? imageBlob : undefined}
            ></Logo>
            {editMode ? (
              <>
                <Icon
                  className={'CompanyeditICON'}
                  style={{ top: '10%' }}
                  icon={'mdi:pencil'}
                ></Icon>
                <div className="uploadbutton">
                  <FileUpload
                    onSelect={select => {
                      console.log('SELECTED IMAGE');
                      setFileAsBlob();
                    }}
                    ref={fileUploadRef}
                    style={{ width: '100%', height: '100%' }}
                    mode="advanced"
                    name="image"
                    onUpload={event => {
                      fileUploadRef.current?.clear();
                    }}
                    accept="image/*"
                    customUpload
                    chooseLabel={'Neues Bild'}
                    uploadHandler={customBase64Uploader}
                  />
                </div>
              </>
            ) : null}
          </div>
          <div className="CompanyExpandRight">
            {!editMode ? (
              <span className="h2Text primaryFontColor">{companyData?.name}</span>
            ) : (
              <div style={{ position: 'relative' }}>
                <Icon className={'CompanyeditICON'} icon={'mdi:pencil'}></Icon>
                <InputText
                  value={companyData?.name}
                  name="companyName"
                  onChange={e => setCompanyData({ ...companyData!, name: e.target.value })}
                />
              </div>
            )}
          </div>
        </div>
        <div className="divider"></div>
        <div className="right">
          <Icon icon={'charm:clipboard-tick'}></Icon>
          <span>45</span>
        </div>
        <div style={{ position: 'absolute', top: 10, right: 10 }}>
          {!editMode ? (
            <CompanySettings
              onEditMode={() => navigation('/company/' + params.companyId + '/edit')}
            ></CompanySettings>
          ) : null}
        </div>
        {editMode ? (
          <div
            style={{
              width: '150px',
              position: 'absolute',
              bottom: '10px',
              right: '10px',
            }}
          >
            <DefaultButton
              loading={loading}
              action={() => uploadFile()}
              text="Speichern"
            ></DefaultButton>
          </div>
        ) : null}
      </div>
      <div>
        {editMode ? (
          <>
            <h3 style={{ textAlign: 'center' }}>Rechnungsdaten</h3>
            <div style={{ marginBottom: '20px' }} className={'invoiceDataLabel'}>
              <span className="p-float-label">
                <InputText
                  id="Firmenname"
                  value={companyData?.InvoiceAddress?.companyName}
                  onChange={e =>
                    setCompanyData({
                      ...companyData,
                      InvoiceAddress: {
                        ...companyData?.InvoiceAddress,
                        companyName: e.target.value,
                      },
                    })
                  }
                />
                <label htmlFor="Firmenname">Firmenname für die Rechnung</label>
              </span>
              <span className="p-float-label">
                <InputText
                  id="email"
                  value={companyData?.InvoiceAddress?.email}
                  onChange={e =>
                    setCompanyData({
                      ...companyData,
                      InvoiceAddress: { ...companyData?.InvoiceAddress, email: e.target.value },
                    })
                  }
                />
                <label htmlFor="email">E-Mail</label>
              </span>
            </div>
            <h4 style={{ textAlign: 'center' }}>Adresse</h4>
            <div className={'invoiceDataLabel'}>
              <span className="p-float-label">
                <InputText
                  id="straße"
                  value={companyData?.InvoiceAddress?.street}
                  onChange={e =>
                    setCompanyData({
                      ...companyData,
                      InvoiceAddress: { ...companyData?.InvoiceAddress, street: e.target.value },
                    })
                  }
                />
                <label htmlFor="Straße">Straße</label>
              </span>
              <span className="p-float-label">
                <InputText
                  id="Hausnummer"
                  value={companyData?.InvoiceAddress?.houseNumber}
                  onChange={e =>
                    setCompanyData({
                      ...companyData,
                      InvoiceAddress: {
                        ...companyData?.InvoiceAddress,
                        houseNumber: e.target.value,
                      },
                    })
                  }
                />
                <label htmlFor="Hausnummer">Hausnummer</label>
              </span>
            </div>
            <div className={'invoiceDataLabel'}>
              <span className="p-float-label">
                <InputText
                  id="Postleitzahl"
                  value={companyData?.InvoiceAddress?.postcode}
                  onChange={e =>
                    setCompanyData({
                      ...companyData,
                      InvoiceAddress: { ...companyData?.InvoiceAddress, postcode: e.target.value },
                    })
                  }
                />
                <label htmlFor="Postleitzahl">Postleitzahl</label>
              </span>
              <span className="p-float-label">
                <InputText
                  id="City"
                  value={companyData?.InvoiceAddress?.city}
                  onChange={e =>
                    setCompanyData({
                      ...companyData,
                      InvoiceAddress: { ...companyData?.InvoiceAddress, city: e.target.value },
                    })
                  }
                />
                <label htmlFor="City">Stadt</label>
              </span>
            </div>
            <div className={'invoiceDataLabel'}>
              <span className="p-float-label">
                <InputText
                  id="Country"
                  value={companyData?.InvoiceAddress?.country}
                  onChange={e =>
                    setCompanyData({
                      ...companyData,
                      InvoiceAddress: { ...companyData?.InvoiceAddress, country: e.target.value },
                    })
                  }
                />
                <label htmlFor="Country">Land</label>
              </span>
              <span className="p-float-label">
                <InputText
                  id="Tax"
                  value={companyData?.InvoiceAddress?.taxNumber}
                  onChange={e =>
                    setCompanyData({
                      ...companyData,
                      InvoiceAddress: { ...companyData?.InvoiceAddress, taxNumber: e.target.value },
                    })
                  }
                />
                <label htmlFor="Tax">Steuernummer</label>
              </span>
            </div>
          </>
        ) : null}
      </div>
    </>
  );
}
